label {
    color: var(--#{$prefix}label-color);
    vertical-align: middle;
}


// Editor
.ql-editor {
    min-height: 300px;
    font-family: $font-family-base !important;
}

.ql-snow {
    .ql-stroke {
        stroke: var(--#{$prefix}label-color);
    }

    .ql-picker-options {
        background-color: var(--#{$prefix}topbar-bg);
    }

    .ql-picker {
        &.ql-header {
            .ql-picker-label,
            .ql-picker-item {
                &:before {
                    color: var(--#{$prefix}label-color);
                }
            }
        }
    }
}

.ql-toolbar {
    font-family: $font-family-base !important;

    &.ql-snow {
        border-top-left-radius: var(--bs-card-inner-border-radius);
        border-top-right-radius: var(--bs-card-inner-border-radius);
        border: 1px solid var(--#{$prefix}border-color);

        .ql-picker {
            &.ql-expanded {
                .ql-picker-label,
                .ql-picker-options {
                    border-color: var(--#{$prefix}border-color);
                }
            }
        }
    }
}


.ql-container.ql-snow {
    border-bottom-left-radius: var(--bs-card-inner-border-radius);
    border-bottom-right-radius: var(--bs-card-inner-border-radius);
    border: 1px solid var(--#{$prefix}border-color);
}

// Drag Drop
.uppy-Root {
    z-index: 99;
    font-family: $font-family-base !important;
}

.uppy-DragDrop-container {
    background-color: $card-bg;
}

.uppy-Dashboard-inner {
    background-color: $card-bg;
    border: 1px solid var(--#{$prefix}border-color);
    height: 250px !important;
}

[data-uppy-drag-drop-supported="true"] .uppy-Dashboard-AddFiles {
    border: 1px dashed var(--#{$prefix}border-color);
}

.uppy-Dashboard-AddFiles-title {
    color: var(--#{$prefix}body-color);
}

.uppy-DragDrop-container:focus {
    box-shadow: none;
}

.uppy-DragDrop--isDragDropSupported {
    border: 2px dashed $gray-300;
}

.uppy-DragDrop-label {
    color: $body-color;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
    background-color: var(--#{$prefix}card-bg);
}

.uppy-StatusBar:not([aria-hidden=true]).is-waiting {
    border-top: 1px solid var(--#{$prefix}border-color);
}

.uppy-Dashboard-Item-name,
.uppy-DashboardContent-title {
    color: var(--#{$prefix}label-color);
}

.uppy-StatusBar,
.uppy-DashboardContent-bar {
    background-color: var(--#{$prefix}card-bg);
    border-bottom: 1px solid var(--#{$prefix}border-color);
}

.uppy-Dashboard-Item {
    border-bottom: 1px solid var(--#{$prefix}border-color);
}

.uppy-Dashboard-Item-action--remove {
    color: $primary;

    &:hover,
    &:focus {
        color: darken($primary, 5%);
        box-shadow: none;
    }
}

.uppy-DashboardContent-addMore:focus,
.uppy-DashboardContent-back:focus,
.uppy-DashboardContent-save:focus {
    background-color: rgba($gray-500, 0.10);
}

.uppy-c-btn-primary:focus {
    box-shadow: none;
}

input {
    &.add-file-input {
        position: absolute;
        opacity: 0;
        right: 0;
        top: 0;
    }
}

//
// Switch
//

@each $color, $value in $theme-colors {
    .form-switch-#{$color} {
        .form-check-input:checked {
            background-color: $value;
            border-color: $value;
        }
    }
}

//   Form Advanced

.selectr-option {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    color: var(--#{$prefix}dropdown-link-color);
    background-color: transparent;

    &.selected {
        background-color: var(--#{$prefix}tertiary-bg);
    }

    &:hover {
        color: $dropdown-link-hover-color;
        background-color: var(--#{$prefix}tertiary-bg);
    }

    &.active {
        color: $dropdown-link-hover-color;
        background-color: var(--#{$prefix}tertiary-bg);
        text-decoration: none;
    }
}

html[data-bs-theme="dark"] {
    .selectr-options-container,
    .selectr-selected {
        background-color: #202221;
    }
}

.selectr-options-container,
.selectr-selected {
    width: 100%;
    padding: $input-padding-y $input-padding-x;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: var(--#{$prefix}body-color);
    appearance: none;
    /*background-color: var(--#{$prefix}card-bg);*/
    background-clip: padding-box;
    border: $input-border-width solid var(--#{$prefix}border-color);
    border-radius: $input-border-radius;
}


.selectr-container {
    &.multiple {
        &.has-selected {
            .selectr-selected {
                padding: 0 $input-padding-x;
                padding-top: calc($input-padding-y - 2px);
                padding-bottom: calc($input-padding-y - 2px);

                &:focus-visible {
                    outline: none;
                }
            }
        }
    }

    &.open {
        .selectr-selected {
            border-color: var(--#{$prefix}border-color) var(--#{$prefix}border-color) transparent;
            border-radius: 3px 3px 0 0;

            &:focus-visible {
                outline: none;
            }
        }
    }
}

.selectr-tag-input {
    background-color: var(--#{$prefix}card-bg);
    padding: 0;

    &:focus-visible {
        outline: none;
    }
}

.taggable {
    .selectr-selected {
        padding: $input-padding-y $input-padding-x;
    }
}

.selectr-input {
    border-color: var(--#{$prefix}border-color) !important;

    &:focus-visible {
        outline: none;
    }
}

.selectr-tag {
    background: $primary;
    border-radius: 3px;
}

.selectr-tag-remove::after,
.selectr-tag-remove::before {
    width: 1px;
}

//  Datepicker
.datepicker.active {
    display: inline-block;
    // border: 1px solid var(--#{$prefix}border-color);
    border-radius: 4px;
}

.datepicker-dropdown.datepicker-orient-top {
    padding-bottom: 0;
}

.datepicker-header .datepicker-controls .button {
    font-weight: 500;
}

.datepicker-controls .button {
    font-size: 15px;
    border: 1px solid var(--#{$prefix}border-color);
    background-color: var(--#{$prefix}body-bg);
    color: $gray-700;
}

.datepicker-view .dow {
    font-weight: 500;
    font-size: 12px;
}

.datepicker-picker {
    background-color: var(--#{$prefix}body-bg);

    span {
        border-radius: 25px;
    }
}

.datepicker-cell.focused:not(.selected),
.datepicker-cell:not(.disabled):hover {
    background-color: $primary;
    color: $white;
}

// Mask
.example__complete {
    display: none;
    margin-left: 5px;
}

.example__complete::after {
    content: "✔";
}

// Form Validation

#form-validation-2 {
    small {
        display: none;
        color: $danger;

        &.error {
            display: block;
        }
    }
}

.form-control {
    &.error {
        border-color: $danger;
    }

    &.success {
        border-color: $success;
    }
}

//    File Upload

.preview-box {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;

    .preview-content {
        width: auto;
        height: 50px;
        max-width: 100%;
        border-radius: 5px;
    }
}


/*
.required {
    label {
        &:after {
            position: absolute;
            content: "*";
            color: #d9534f;
            margin-left: 0.25em;
        }
    }
}
 */

 label {
    &.required::after {
        position: static;
        content: "*";
        color: #d9534f;
        margin-left: 0.25em;
    }
}


.required {
    label::after {
        position: static;
        content: "*";
        color: #d9534f;
        margin-left: 0.25em;
    }
}

.error {
    &::after {
        content: none !important;
    }
}



