// Alert
.alert-dismissible {
    .btn-close {
        font-size: 22px;
        padding: $alert-padding-y $alert-padding-x;
    }
}

.border-theme-white {
    border: 1px solid var(--#{$prefix}theme-white-color);
}

.border-theme-white-2 {
    border: 2px solid var(--#{$prefix}theme-white-color);
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

.error-message-box {
    position: fixed;
    right: 2%;
    top:10%;
    z-index: 999999 !important;
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}


.error-message-box.active {
    animation: slideIn 0.2s ease-out forwards;
}


.error-message-box.hide {
    animation: slideOut 0.2s ease-in forwards;
}
