//
// Prismjs
// 

code[class*="language-"],
pre[class*="language-"] {
    color: #9efeff;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    tab-size: 4;
    hyphens: none;
    font-weight: 400;
    font-size: 14px;
    line-height: $line-height-base;
    letter-spacing: 0.5px;
    text-shadow: 0 1px #222245;
}
code[class*="language-"] ::-moz-selection,
code[class*="language-"] ::selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"]::selection,
pre[class*="language-"] ::-moz-selection,
pre[class*="language-"] ::selection,
pre[class*="language-"]::-moz-selection,
pre[class*="language-"]::selection {
    color: inherit;
    background: #a599e9;
}
pre[class*="language-"] {
    padding: 2em;
    margin: 0;
    overflow: auto;
}
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
    background: #151516;
}
:not(pre) > code[class*="language-"] {
    padding: 0.1em;
    border-radius: 0.3em;
}
.token {
    font-weight: 400;
}
.token.cdata,
.token.comment,
.token.prolog {
    color: #b362ff;
}
.token.atrule,
.token.delimiter,
.token.important,
.token.keyword,
.token.selector {
    color: #ff9d00;
}
.token.attr-name,
.token.operator {
    color: #ffb454;
}
.token.punctuation {
    color: #fff;
}
.token.boolean {
    color: #ff628c;
}
.token.builtin,
.token.doctype,
.token.tag,
.token.tag .punctuation {
    color: #ff9d00;
}
.token.entity,
.token.symbol {
    color: #6897bb;
}
.token.number {
    color: #ff628c;
}
.token.constant,
.token.property,
.token.variable {
    color: #ff628c;
}
.token.char,
.token.string {
    color: #a5ff90;
}
.token.attr-value,
.token.attr-value .punctuation {
    color: #a5c261;
}
.token.attr-value .punctuation:first-child {
    color: #a9b7c6;
}
.token.url {
    color: #287bde;
    text-decoration: underline;
}
.token.function {
    color: #fad000;
}
.token.regex {
    background: #364135;
}
.token.bold {
    font-weight: 700;
}
.token.italic {
    font-style: italic;
}
.token.inserted {
    background: #0f0;
}
.token.deleted {
    background: #ff000d;
}
code.language-css .token.property,
code.language-css .token.property + .token.punctuation {
    color: #a9b7c6;
}
code.language-css .token.id {
    color: #ffc66d;
}
code.language-css .token.selector > .token.attribute,
code.language-css .token.selector > .token.class,
code.language-css .token.selector > .token.pseudo-class,
code.language-css .token.selector > .token.pseudo-element {
    color: #ffc66d;
}
.token.class-name {
    color: #fb94ff;
}
.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url {
    background: 0 0;
}
.line-highlight.line-highlight {
    margin-top: 36px;
    background: linear-gradient(to right, rgba(179, 98, 255, 0.17), transparent);
}
.line-highlight.line-highlight:before,
.line-highlight.line-highlight[data-end]:after {
    content: "";
}

// Scrollbar
pre.language-html,
pre.language-css,
pre.language-javascript {
    max-height: 220px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
        width: 5px;
        margin-right: 5px;
    }
    &::-webkit-scrollbar:horizontal {
        height: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 10px;
        margin-right: 5px;
        border: none;
        transition: all 0.4s ease-out;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background-color: rgba($light, 0.3);
            transition: all 0.4s ease-out;
        }
    }
}
