//
// Timeline
// 




.activity{
  .activity-info {
    display: flex;
    margin:5px 0;
    position: relative;
    min-height: 60px;
    &::before{
      content: '';
      position: absolute;      
      bottom: 0;
      top: 42px;
      left: 18px;
      border-left: 2px dotted var(--#{$prefix}border-color);
    }
    .icon-info-activity i{
      width: 36px;
      height: 36px;      
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: $text-muted;
      box-shadow: 0px 0px 1px 0.25px;
      font-size: 18px;
      border: 1px solid var(--#{$prefix}border-color);
      margin-left: 2px;
    }
    .activity-info-text {
      margin-left: 16px;
      width: 100%;
      a,
      span{
        color: $gray-700;
      }
    }
  }
}

.icon-item {
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
}
.icon-item.icon-item-md {
  height: 2.5rem;
  width: 2.5rem;
}


.tracking-item {
	border-left: 1px solid var(--#{$prefix}border-color);
	position: relative;
	padding: 24px 30px;
	margin-left: 150px;
	.tracking-date {
		position: absolute;
		left: -160px;
		width: 120px;
		text-align: right;
    font-weight: 500;
    font-size: 12px;
	}
	.tracking-icon {
		line-height: 18px;
    position: absolute;
    left: -9px;
    width: 18px;
    height: 18px;
    text-align: center;
    border-radius: 50%;
    background-color: $card-bg;
		&.icon-inner {
			color: $gray-500;
      font-size: 4px;
      box-shadow: 0px 0px 1px 1px $gray-400;
		}
	}
}