/*
Template Name: Rizz - Admin & Dashboard Template
Author: Mannatthemes
Version: 2.2.0
File: footer Css
*/

.footer {
    bottom: 0;
    position: absolute;
    right: 0;
    left: 0;
    background-color: var(--#{$prefix}body-bg);
}
