// 
// _ribbons.scss
// 


.ribbon1 {
  position: absolute;
  top: -6.1px;
  right: 10px;  
  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-top: 10px solid;
  }
  
  span {
    display: block;
    padding: 6px 4px 0px;
    border-top-right-radius: 6px;
    width: 60px;
    font-size: 11px;
    font-weight: 500;
    &:before,
    &:after{
      position: absolute;
      content: "";
    }
    &:before {
      height: 6px;
      width: 4px;
      left: -4px;
      top: 0;
     }
     &:after {
      height: 6px;
      width: 6px;
      left: -6px;
      top: 0;
      border-radius: 8px 8px 0 0;
     }

     @each $color, $value in $theme-colors {
      &.rib1-#{$color}, &:before {
        background: $value;
      }
      &.rib1-#{$color} {
        &:after{
          background: darken($value, 15%);
        }
      } 
    }
  }
  
  @each $color, $value in $theme-colors {
    &.rib1-#{$color} {
      &:after{
        border-top-color:$value;
      }
    } 
  }
}

//Ribbon2

.ribbon2 {
  width: 30px;
  padding: 8px 0;
  position: absolute;
  top: -6px;
  right: 12px;
  text-align: center;
  border-top-left-radius: 3px;
  
  &:before, 
  &:after {
    content: "";
    position: absolute;
  }
  &:before{
    height: 0;
    width: 0;
    right: -5.5px;
    top: 0.1px;
    border-right: 6px solid transparent;
  }
  &:after {
    height: 0;
    width: 0;
    bottom: -18px;
    left: 0;    
    border-bottom: 18px solid transparent;
  }
  @each $color, $value in $theme-colors {
    &.rib2-#{$color} {
      background: $value;
      &:before{
        border-bottom: 6px solid darken($value, 15%);
      }
      &:after{
        border-left: 15px solid $value;
        border-right: 15px solid $value;
      }
    } 
  }
}

 // ribbon-3

 .ribbon3 {
   text-align: center;
  width: 70px;
  height: 26px;
  line-height: 26px;
  padding-right: 12px;
  position: absolute;
  right: -8px;
  top: 10px;
  font-size: 11px;
  font-weight: 500;
  &:before, 
  &:after {
    content: "";
    position: absolute;
  }
  &:before {
    height: 0;
    width: 0;
    bottom: -8.5px;
    right: 0.1px;   
    border-right: 9px solid transparent;
  }
  &:after {
    height: 0;
    width: 0;
    left: -14.5px;
    border-bottom: 13px solid transparent;
    border-top: 13px solid transparent;
  }
  @each $color, $value in $theme-colors {
    &.rib3-#{$color} {
      background: $value;
      &:before{
        border-top: 9px solid darken($value, 15%);
      }
      &:after{
        border-right: 15px solid $value;
      }
    } 
  }
}

//ribbon-4
.ribbon4 {
  width: 100%;
  height: 188px;
  position: absolute;
  top: -8px;
  left: 8px;
  overflow: hidden;
  &:before,
  &:after {
    content: ""; 
    position: absolute;
  }
  &:before {
    width: 40px;
    height: 8px;
    right: 44px;
    border-radius: 8px 8px 0px 0px;
  }
  &:after {
    width: 8px;
    height: 40px;
    right: 0px;
    top: 44px;
    border-radius: 0px 8px 8px 0px;
  }
  @each $color, $value in $theme-colors {
    &.rib4-#{$color} {
      &:after, &:before{
        background: darken($value, 10%);
      }
    }     
  }
  
  .ribbon4-band {
    width: 170px;
    height: 20px;
    line-height: 20px;
    position: absolute;
    top: 16px;
    right: -50px;
    z-index: 2;
    overflow: hidden;
    transform: rotate(45deg);
    border: 1px dashed;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    @each $color, $value in $theme-colors {
      &.ribbon4-band-#{$color}{
        box-shadow:0 0 0 3px $value,  0px 21px 5px -18px rgba($gray-700,.6);
        background: $value;
      } 
    }
  }  
}

