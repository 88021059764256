//
// Sweet Alert
// 


.swal2-popup {
  background: $card-bg;
  padding: $card-spacer-y $card-spacer-x;
  .swal2-title{
    font-size: 20px;
    font-weight: 500;
    color: var(--#{$prefix}body-color);
  }
  .swal2-content{
    color: $gray-600;
    font-size: 15px;
  }
  &.swal2-toast{
    background-color: $card-bg;
    box-shadow: 0 0 0.625em rgba($gray-800, 0.2);
    .swal2-title{
      line-height: 1.2;
    }
  }
  .swal2-styled {
    display: inline-block;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      cursor: pointer;
      user-select: none;
      background-color: transparent;
      border: none;
      padding: .375rem .75rem;
      font-size: .8125rem;
      border-radius: .25rem;
      &:focus{
        box-shadow: none;
      }
      &.swal2-confirm{
        font-size: $font-size-base;
        background-color: $success;
        &:focus{
          box-shadow: none;
        }
      }
      &.swal2-deny {
        background-color: $primary;
        font-size: $font-size-base;
        &:focus{
          box-shadow: none;
        }
      }
      &.swal2-cancel{
        font-size: $font-size-base;
        background-color: $danger;
        &:focus{
          box-shadow: none;
        }
      }
    }
}



.swal2-close:focus{
  box-shadow: none;
}
.swal2-footer{
  border-top: 1px solid var(--#{$prefix}border-color);
}

.swal2-actions {
  .btn-success,
  .btn-danger{
    border: none;
    &:focus{
      box-shadow: none;
    }
  }
}

div:where(.swal2-container) {
  .swal2-html-container{
    color: var(--#{$prefix}text-muted);    
  }
  input:where(.swal2-input),
    input:where(.swal2-file), 
    textarea:where(.swal2-textarea){
      border: 1px solid var(--#{$prefix}border-color);
      box-shadow: none;
      color: var(--#{$prefix}body-color);
      &:focus{
        box-shadow: none;
        border: 1px solid $primary;
      }
  }
}
.swal2-icon{
  width: 3em;
  height: 3em;
  margin: 1em auto 1em;
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  top: 22px;
  width: 30px;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 9px;
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 9px;
}

.swal2-icon .swal2-icon-content {
  font-size: 40px;
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  width: 40px;
  height: 70px;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -23px;
  left: -13px;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -12px;
  left: 36px;
}
.swal2-icon.swal2-success .swal2-success-fix {
  top: 0px;
  left: 12px;
  width: 6px;
  height: 60px;
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 28px;
  left: 6px;
  width: 18px;
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 23px;
  right: 4px;
  width: 30px;
}

@-webkit-keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 24px;
    left: 4px;
    width: 0
  }
  70% {
    top: 27px;
    left: 0px;
    width: 22px
  }
  84% {
    top: 30px;
    left: 8px;
    width: 16px
  }
  100% {
    top: 28px;
    left: 6px;
    width: 18px
  }
}
@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 24px;
    left: 4px;
    width: 0
  }
  70% {
    top: 27px;
    left: 0px;
    width: 22px
  }
  84% {
    top: 30px;
    left: 8px;
    width: 16px
  }
  100% {
    top: 28px;
    left: 6px;
    width: 18px
  }
}
@-webkit-keyframes swal2-animate-success-line-long {
  0% {
    top: 26px;
    right: 10px;
    width: 0;
  }
  65% {
    top: 26px;
    right: 10px;
    width: 0;
  }
  84% {
    top: 22px;
    right: 0;
    width: 34px;
  }
  100% {
    top: 23px;
    right: 4px;
    width: 30px;
  }
}
@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 40px;
    right: 30px;
    width: 0;
  }
  84% {
    top: 22px;
    right: 0;
    width: 34px;
  }
  100% {
    top: 23px;
    right: 4px;
    width: 30px;
  }
}

