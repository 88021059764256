
// Dropdowns
.dropstart .dropdown-toggle::before,
.dropend .dropdown-toggle::after,
.dropup .dropdown-toggle::after,
.dropdown-toggle::after {
    display: none;
}


.dropdown-lg {
    width: 320px;
}