//
// Maps
// 

.gmaps {
    height: 300px;
    background: $light;
    border-radius: 3px;
}

.overlay {
    display: block;
    text-align: center;
    color: $white;
    font-size: 14px;
    line-height: 20px;
    background: $primary;
    border-radius: 4px;
    padding: 5px 15px;
}

.overlay_arrow {
    left: 50%;
    margin-left: -16px;
    width: 0;
    height: 0;
    position: absolute;
}

.overlay_arrow.above {
    bottom: -9px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $primary;
}

.overlay_arrow.below {
    top: -9px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $primary;
}



.svgMap-map-wrapper {
    position: relative;
    width: 100%;
    padding-top: 50%;
    overflow: hidden;
    background: $light;
}

// Vector Map
.jvm-container {
    text {
        font-family: $font-family-base !important;
        fill: var(--#{$prefix}heading-color) !important;
    }
}

.jvm-tooltip {
    background-color: $primary;
    font-family: $font-family-base !important;
}