
// Breadcrumb

.breadcrumb-item + {
    .breadcrumb-item {
        &:before {
            content: "\f105";
            font-family: "Line Awesome Free";
            font-weight: 900;
        }
    }
}