@charset "UTF-8";
/*
Template Name: Rizz - Admin & Dashboard Template
Author: Mannatthemes
Version: 3.1.0
File: App Css
*/
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600&display=swap");
.alert-dismissible .btn-close {
  font-size: 22px;
  padding: 1.5rem 1.5rem;
}

.border-theme-white {
  border: 1px solid var(--bs-theme-white-color);
}

.border-theme-white-2 {
  border: 2px solid var(--bs-theme-white-color);
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
.error-message-box {
  position: fixed;
  right: 2%;
  top: 10%;
  z-index: 999999 !important;
  opacity: 0;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.error-message-box.active {
  animation: slideIn 0.2s ease-out forwards;
}

.error-message-box.hide {
  animation: slideOut 0.2s ease-in forwards;
}

.breadcrumb-item + .breadcrumb-item:before {
  content: "\f105";
  font-family: "Line Awesome Free";
  font-weight: 900;
}

.btn-primary {
  box-shadow: 0 1px 2px 0 rgba(0, 91, 111, 0.35);
}
.btn-primary:hover {
  box-shadow: none;
}

.btn-secondary {
  box-shadow: 0 1px 2px 0 rgba(149, 160, 197, 0.35);
}
.btn-secondary:hover {
  box-shadow: none;
}

.btn-success {
  box-shadow: 0 1px 2px 0 rgba(34, 197, 173, 0.35);
}
.btn-success:hover {
  box-shadow: none;
}

.btn-info {
  box-shadow: 0 1px 2px 0 rgba(65, 203, 216, 0.35);
}
.btn-info:hover {
  box-shadow: none;
}

.btn-warning {
  box-shadow: 0 1px 2px 0 rgba(255, 159, 67, 0.35);
}
.btn-warning:hover {
  box-shadow: none;
}

.btn-danger {
  box-shadow: 0 1px 2px 0 rgba(239, 77, 86, 0.35);
}
.btn-danger:hover {
  box-shadow: none;
}

.btn-pink {
  box-shadow: 0 1px 2px 0 rgba(253, 60, 151, 0.35);
}
.btn-pink:hover {
  box-shadow: none;
}

.btn-purple {
  box-shadow: 0 1px 2px 0 rgba(115, 103, 240, 0.35);
}
.btn-purple:hover {
  box-shadow: none;
}

.btn-blue {
  box-shadow: 0 1px 2px 0 rgba(13, 110, 253, 0.35);
}
.btn-blue:hover {
  box-shadow: none;
}

.btn-light {
  box-shadow: 0 1px 2px 0 rgba(244, 246, 249, 0.35);
}
.btn-light:hover {
  box-shadow: none;
}

.btn-dark {
  box-shadow: 0 1px 2px 0 rgba(43, 45, 59, 0.35);
}
.btn-dark:hover {
  box-shadow: none;
}

.text-gray-100 {
  color: #f4f6f9;
}

.text-gray-200 {
  color: #eaeff5;
}

.text-gray-300 {
  color: #d9e1ec;
}

.text-gray-400 {
  color: #c1cde0;
}

.text-gray-500 {
  color: #a8b5d1;
}

.text-gray-600 {
  color: #95a0c5;
}

.text-gray-700 {
  color: #7a82b1;
}

.text-gray-800 {
  color: #555b7e;
}

.text-gray-900 {
  color: #2b2d3b;
}

.btn-outline-light {
  color: #7a82b1;
  background-image: none;
  background-color: transparent;
  border-color: #d9e1ec;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border-color: transparent;
}

.modal-header .btn-close {
  font-size: 20px;
}

html[data-bs-theme=dark] .btn-light {
  box-shadow: none;
  --bs-btn-color: #f4f6f9;
  --bs-btn-bg: #303231;
  --bs-btn-border-color: #2b2d3b;
  --bs-btn-hover-color: #f4f6f9;
  --bs-btn-hover-bg: #343635;
  --bs-btn-hover-border-color: #313341;
  --bs-btn-focus-shadow-rgb: 75, 77, 88;
  --bs-btn-active-color: #f4f6f9;
  --bs-btn-active-bg: #343635;
  --bs-btn-active-border-color: #313341;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #f4f6f9;
  --bs-btn-disabled-bg: #2b2d3b;
  --bs-btn-disabled-border-color: #2b2d3b;
}
html[data-bs-theme=dark] .btn-dark {
  --bs-btn-color: #303231;
  --bs-btn-bg: #f4f6f9;
  --bs-btn-border-color: #f4f6f9;
  --bs-btn-hover-color: #303231;
  --bs-btn-hover-bg: #e8eaed;
  --bs-btn-hover-border-color: #dcdde0;
  --bs-btn-focus-shadow-rgb: 207, 209, 212;
  --bs-btn-active-color: #303231;
  --bs-btn-active-bg: #dcdde0;
  --bs-btn-active-border-color: #cfd1d4;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #303231;
  --bs-btn-disabled-bg: #f4f6f9;
  --bs-btn-disabled-border-color: #f4f6f9;
}
html[data-bs-theme=dark] .btn-outline-light {
  color: #c1cde0;
  background-image: none;
  background-color: transparent;
  border-color: #555b7e;
}
html[data-bs-theme=dark] .btn-outline-light:active {
  color: #eaeff5;
  background-image: none;
  background-color: transparent;
  border-color: #7a82b1;
}

/* for switch/toggle button*/
.custom_toggle_switch .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}
.custom_toggle_switch .switch input {
  display: none;
}
.custom_toggle_switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bs-success);
  transition: 0.4s;
  border-radius: 24px;
}
.custom_toggle_switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: var(--bs-light-bg-subtle);
  transition: 0.4s;
  border-radius: 50%;
}
.custom_toggle_switch input:checked + .slider {
  background-color: var(--bs-primary);
}
.custom_toggle_switch input:checked + .slider:before {
  transform: translateX(16px);
}

.card {
  box-shadow: 0 3px 16px rgba(142, 134, 171, 0.05);
  border: none;
  margin-bottom: 1.5rem;
}
.card .card-header {
  border-bottom: none;
  padding-top: var(--bs-card-spacer-x);
}
.card .card-header .card-title {
  margin-bottom: 0;
  font-size: 16px;
  color: var(--bs-heading-color);
}

.card-h-100 {
  height: calc(100% - 1.5rem);
}

.offer-card {
  background-image: url("../images/extra/pet-bg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000000;
}

.dropstart .dropdown-toggle::before,
.dropend .dropdown-toggle::after,
.dropup .dropdown-toggle::after,
.dropdown-toggle::after {
  display: none;
}

.dropdown-lg {
  width: 320px;
}

label {
  color: var(--bs-label-color);
  vertical-align: middle;
}

.ql-editor {
  min-height: 300px;
  font-family: var(--bs-font-sans-serif) !important;
}

.ql-snow .ql-stroke {
  stroke: var(--bs-label-color);
}
.ql-snow .ql-picker-options {
  background-color: var(--bs-topbar-bg);
}
.ql-snow .ql-picker.ql-header .ql-picker-label:before,
.ql-snow .ql-picker.ql-header .ql-picker-item:before {
  color: var(--bs-label-color);
}

.ql-toolbar {
  font-family: var(--bs-font-sans-serif) !important;
}
.ql-toolbar.ql-snow {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border: 1px solid var(--bs-border-color);
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: var(--bs-border-color);
}

.ql-container.ql-snow {
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border: 1px solid var(--bs-border-color);
}

.uppy-Root {
  z-index: 99;
  font-family: var(--bs-font-sans-serif) !important;
}

.uppy-DragDrop-container {
  background-color: var(--bs-secondary-bg);
}

.uppy-Dashboard-inner {
  background-color: var(--bs-secondary-bg);
  border: 1px solid var(--bs-border-color);
  height: 250px !important;
}

[data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
  border: 1px dashed var(--bs-border-color);
}

.uppy-Dashboard-AddFiles-title {
  color: var(--bs-body-color);
}

.uppy-DragDrop-container:focus {
  box-shadow: none;
}

.uppy-DragDrop--isDragDropSupported {
  border: 2px dashed #d9e1ec;
}

.uppy-DragDrop-label {
  color: #2b2d3b;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  background-color: var(--bs-card-bg);
}

.uppy-StatusBar:not([aria-hidden=true]).is-waiting {
  border-top: 1px solid var(--bs-border-color);
}

.uppy-Dashboard-Item-name,
.uppy-DashboardContent-title {
  color: var(--bs-label-color);
}

.uppy-StatusBar,
.uppy-DashboardContent-bar {
  background-color: var(--bs-card-bg);
  border-bottom: 1px solid var(--bs-border-color);
}

.uppy-Dashboard-Item {
  border-bottom: 1px solid var(--bs-border-color);
}

.uppy-Dashboard-Item-action--remove {
  color: #005b6f;
}
.uppy-Dashboard-Item-action--remove:hover, .uppy-Dashboard-Item-action--remove:focus {
  color: #004656;
  box-shadow: none;
}

.uppy-DashboardContent-addMore:focus,
.uppy-DashboardContent-back:focus,
.uppy-DashboardContent-save:focus {
  background-color: rgba(168, 181, 209, 0.1);
}

.uppy-c-btn-primary:focus {
  box-shadow: none;
}

input.add-file-input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
}

.form-switch-primary .form-check-input:checked {
  background-color: #005b6f;
  border-color: #005b6f;
}

.form-switch-secondary .form-check-input:checked {
  background-color: #95a0c5;
  border-color: #95a0c5;
}

.form-switch-success .form-check-input:checked {
  background-color: #22c5ad;
  border-color: #22c5ad;
}

.form-switch-info .form-check-input:checked {
  background-color: #41cbd8;
  border-color: #41cbd8;
}

.form-switch-warning .form-check-input:checked {
  background-color: #ff9f43;
  border-color: #ff9f43;
}

.form-switch-danger .form-check-input:checked {
  background-color: #ef4d56;
  border-color: #ef4d56;
}

.form-switch-pink .form-check-input:checked {
  background-color: #fd3c97;
  border-color: #fd3c97;
}

.form-switch-purple .form-check-input:checked {
  background-color: #7367f0;
  border-color: #7367f0;
}

.form-switch-blue .form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-switch-light .form-check-input:checked {
  background-color: #f4f6f9;
  border-color: #f4f6f9;
}

.form-switch-dark .form-check-input:checked {
  background-color: #2b2d3b;
  border-color: #2b2d3b;
}

.selectr-option {
  padding: 0.375rem 1.5rem;
  color: var(--bs-dropdown-link-color);
  background-color: transparent;
}
.selectr-option.selected {
  background-color: var(--bs-tertiary-bg);
}
.selectr-option:hover {
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
}
.selectr-option.active {
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  text-decoration: none;
}

html[data-bs-theme=dark] .selectr-options-container,
html[data-bs-theme=dark] .selectr-selected {
  background-color: #202221;
}

.selectr-options-container,
.selectr-selected {
  width: 100%;
  padding: 0.517rem 0.95rem;
  font-size: 0.812rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  /*background-color: var(--bs-card-bg);*/
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.selectr-container.multiple.has-selected .selectr-selected {
  padding: 0 0.95rem;
  padding-top: calc(0.517rem - 2px);
  padding-bottom: calc(0.517rem - 2px);
}
.selectr-container.multiple.has-selected .selectr-selected:focus-visible {
  outline: none;
}
.selectr-container.open .selectr-selected {
  border-color: var(--bs-border-color) var(--bs-border-color) transparent;
  border-radius: 3px 3px 0 0;
}
.selectr-container.open .selectr-selected:focus-visible {
  outline: none;
}

.selectr-tag-input {
  background-color: var(--bs-card-bg);
  padding: 0;
}
.selectr-tag-input:focus-visible {
  outline: none;
}

.taggable .selectr-selected {
  padding: 0.517rem 0.95rem;
}

.selectr-input {
  border-color: var(--bs-border-color) !important;
}
.selectr-input:focus-visible {
  outline: none;
}

.selectr-tag {
  background: #005b6f;
  border-radius: 3px;
}

.selectr-tag-remove::after,
.selectr-tag-remove::before {
  width: 1px;
}

.datepicker.active {
  display: inline-block;
  border-radius: 4px;
}

.datepicker-dropdown.datepicker-orient-top {
  padding-bottom: 0;
}

.datepicker-header .datepicker-controls .button {
  font-weight: 500;
}

.datepicker-controls .button {
  font-size: 15px;
  border: 1px solid var(--bs-border-color);
  background-color: var(--bs-body-bg);
  color: #7a82b1;
}

.datepicker-view .dow {
  font-weight: 500;
  font-size: 12px;
}

.datepicker-picker {
  background-color: var(--bs-body-bg);
}
.datepicker-picker span {
  border-radius: 25px;
}

.datepicker-cell.focused:not(.selected),
.datepicker-cell:not(.disabled):hover {
  background-color: #005b6f;
  color: #ffffff;
}

.example__complete {
  display: none;
  margin-left: 5px;
}

.example__complete::after {
  content: "✔";
}

#form-validation-2 small {
  display: none;
  color: #ef4d56;
}
#form-validation-2 small.error {
  display: block;
}

.form-control.error {
  border-color: #ef4d56;
}
.form-control.success {
  border-color: #22c5ad;
}

.preview-box {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
}
.preview-box .preview-content {
  width: auto;
  height: 50px;
  max-width: 100%;
  border-radius: 5px;
}

/*
.required {
    label {
        &:after {
            position: absolute;
            content: "*";
            color: #d9534f;
            margin-left: 0.25em;
        }
    }
}
 */
label.required::after {
  position: static;
  content: "*";
  color: #d9534f;
  margin-left: 0.25em;
}

.required label::after {
  position: static;
  content: "*";
  color: #d9534f;
  margin-left: 0.25em;
}

.error::after {
  content: none !important;
}

.modal-content {
  border: none;
}

.modal-header {
  background-color: #2b2d3b;
}
.modal-header .modal-title {
  color: #ffffff;
  font-size: 15px;
}
.modal-header .btn-close {
  font-size: 20px;
}

.page-wrapper .page-content {
  width: 100%;
  margin-left: var(--bs-startbar-width);
  position: relative;
  min-height: calc(100vh - var(--bs-topbar-height));
  padding: 0 8px 68px 8px;
  margin-top: var(--bs-topbar-height);
}
.page-wrapper .page-content .page-title-box {
  padding: 16px 0;
}
.page-wrapper .page-content .page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  font-weight: 600;
}
.page-wrapper .page-content .page-title-box .breadcrumb .breadcrumb-item a {
  color: #7a82b1;
  font-weight: 400;
  font-size: 13px;
}

@media (min-width: 680px) {
  .page-wrapper {
    display: flex;
  }
}
.shape {
  position: absolute;
  pointer-events: none;
  right: 0;
  bottom: 0;
  left: 0;
}
.shape > svg {
  transform: scale(2);
  width: 100%;
  height: auto;
  transform-origin: top center;
}

.ex-page-content h1 {
  font-size: 98px;
  font-weight: 700;
  text-shadow: rgba(137, 151, 189, 0.2) 1px 1px, rgba(137, 151, 189, 0.2) 2px 2px, rgba(137, 151, 189, 0.2) 3px 3px;
}

.img-group .user-avatar:hover {
  z-index: 2;
}

.task-accordion.accordion-item:not(:first-of-type) {
  border-top: 1px;
}

.color-bg {
  background-image: url("../images/extra/color-bg-2.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.social {
  border: 1px solid #a8b5d1;
  transition: 0.3s;
}
.social i {
  color: #a8b5d1;
}
.social.twitter:hover {
  border-color: #1da1f2;
  background-color: #1da1f2;
}
.social.twitter:hover i {
  color: #ffffff;
}
.social.instagram:hover {
  border-color: #f09433;
  background: #f09433;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
}
.social.instagram:hover i {
  color: #ffffff;
}
.social.facebook:hover {
  border-color: #1877f2;
  background-color: #1877f2;
}
.social.facebook:hover i {
  color: #ffffff;
}

.list-arrow {
  position: relative;
}
.list-arrow::before {
  content: "\f178";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #005b6f;
  position: absolute;
  left: -22px;
}

.flatpickr-calendar, .flatpickr-calendar.arrowTop:after, .flatpickr-calendar.arrowBottom:after, .flatpickr-months .flatpickr-month, .flatpickr-current-month .flatpickr-monthDropdown-months, span.flatpickr-weekday, .flatpickr-weekdays {
  background: var(--bs-body-bg);
  border-radius: var(--bs-card-border-radius);
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: var(--bs-card-bg);
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #005b6f;
  border-color: #005b6f;
}

.flatpickr-calendar.hasTime.animate.arrowLeft.open.arrowBottom {
  border-radius: 10px;
}

.progress {
  background-color: var(--bs-border-color);
}

/* Progressbar Vertical */
.progress-vertical {
  min-height: 250px;
  height: 250px;
  width: 8px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
  box-shadow: none;
}
.progress-vertical .progress-bar {
  width: 100%;
}

.progress-vertical-bottom {
  min-height: 250px;
  height: 250px;
  position: relative;
  width: 8px;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
  box-shadow: none;
}
.progress-vertical-bottom .progress-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.progress-vertical.progress-sm,
.progress-vertical-bottom.progress-sm {
  width: 5px !important;
}
.progress-vertical.progress-sm .progress-bar,
.progress-vertical-bottom.progress-sm .progress-bar {
  font-size: 8px;
  line-height: 5px;
}
.progress-vertical.progress-md,
.progress-vertical-bottom.progress-md {
  width: 15px !important;
}
.progress-vertical.progress-md .progress-bar,
.progress-vertical-bottom.progress-md .progress-bar {
  font-size: 10.8px;
  line-height: 14.4px;
}
.progress-vertical.progress-lg,
.progress-vertical-bottom.progress-lg {
  width: 20px !important;
}
.progress-vertical.progress-lg .progress-bar,
.progress-vertical-bottom.progress-lg .progress-bar {
  font-size: 12px;
  line-height: 20px;
}

.progress-icon-spin {
  font-size: 36px;
}

:root {
  --bs-topbar-height: 120px;
  --bs-topbar-bg: #fff;
  --bs-topbar-b-border-color: #dee2e6;
  --bs-topbar-nav-icon-bg: #96a0b5;
  --bs-topbar-nav-icon-color: #96a0b5;
  --bs-startbar-width: 270px;
  --bs-startbar-collapsed-width: 70px;
  --bs-startbar-bg: #fff;
  --bs-startbar-e-border-color: #dee2e6;
  --bs-menu-icon-color: #96a0b5;
  --bs-menu-link-color: #061237;
  --bs-nav-link-disc-color: #8997bd;
  --bs-menu-label-color: #717fa7;
  --bs-label-color: #656d9a;
  --bs-text-muted: var(--bs-secondary-color);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-theme-white-color: #ffffff;
  --bs-border-secondary: #95a0c5;
}

html[data-bs-theme=dark] {
  --bs-light: #303231;
  --bs-light-rgb: 48, 50, 49;
  --bs-dark: #f4f6f9;
  --bs-dark-rgb: 244, 246, 249;
  --bs-label-color: #9797a5;
  --bs-theme-white-color: #2e2e31;
  --bs-body-color: #d9e1ec;
  --bs-text-muted: #aab0b9;
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.35);
  --bs-topbar-bg: #202221;
  --bs-topbar-b-border-color: #d9e1ec;
  --bs-topbar-nav-icon-bg: #2c3640;
  --bs-topbar-nav-icon-color: #a1a8bd;
  --bs-secondary: #c3c3c3;
  --bs-secondary-rgb: 195, 195, 195;
  --bs-border-secondary: #333645;
  --bs-startbar-bg: #202221;
  --bs-startbar-e-border-color: #d9e1ec;
  --bs-menu-icon-color: #4d6089;
  --bs-menu-link-color: #a1a8bd;
  --bs-nav-link-disc-color: #8997bd;
  --bs-menu-label-color: #717fa7;
}

html[data-startbar=dark] {
  --bs-startbar-bg: #202221;
  --bs-startbar-e-border-color: #d9e1ec;
  --bs-menu-icon-color: #4d6089;
  --bs-menu-link-color: #a1a8bd;
  --bs-nav-link-disc-color: #8997bd;
  --bs-menu-label-color: #717fa7;
  --bs-dark: #f4f6f9;
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.35);
}

.spinner-border.spinner-border-custom-1 {
  border: 5px double currentColor;
  border-right-color: transparent;
}
.spinner-border.spinner-border-custom-2 {
  border: 5px dotted currentColor;
  border-right-color: transparent;
}
.spinner-border.spinner-border-custom-3 {
  border: 5px groove currentColor;
  border-right-color: transparent;
}
.spinner-border.spinner-border-custom-4 {
  border: 5px dashed currentColor;
  border-right-color: transparent;
}
.spinner-border.spinner-border-custom-5 {
  border: 5px outset currentColor;
  border-right-color: transparent;
}

.table th {
  font-weight: 500;
  vertical-align: middle;
}
.table td {
  font-weight: 400;
  vertical-align: middle;
}
.table.border-dashed th {
  font-weight: 500;
  border-top: 1px dashed var(--bs-border-color);
}
.table.border-dashed td {
  border-top: 1px dashed var(--bs-border-color);
}
.table.border-dashed thead th {
  border-bottom: 1px dashed var(--bs-border-color);
}
.table tbody tr:last-child th,
.table tbody tr:last-child td {
  border-bottom: none;
}

.table thead th {
  border-bottom: none;
}
.table thead th:first-child {
  border-radius: 8px 0 0 8px;
}
.table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
.table tbody tr:last-child td {
  border-bottom: none;
}

.table th,
.table td {
  vertical-align: middle;
}

.table tbody,
.table td,
.table tfoot,
.table th,
.table thead,
.table tr {
  border-style: dashed;
}

.datatable-table > thead {
  background-color: var(--bs-light) !important;
}
.datatable-table > thead > tr > th {
  color: #7a82b1;
  font-weight: 500;
  background-color: var(--bs-light);
}

.dataTable-top,
.datatable-top {
  padding-top: 0;
}

.datatable-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.datatable-disabled {
  visibility: visible !important;
}

@media (max-width: 767.98px) {
  .dataTable-top,
  .datatable-top {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: space-between;
  }
}
.dataTable-bottom,
.datatable-bottom {
  margin-top: 1.5rem;
  padding: 0;
}

.dataTable-table > tfoot > tr > td,
.dataTable-table > tfoot > tr > th,
.dataTable-table > thead > tr > td,
.dataTable-table > thead > tr > th,
.dataTable-table > tbody > tr > td,
.dataTable-table > tbody > tr > th,
.datatable-table > tfoot > tr > td,
.datatable-table > tfoot > tr > th,
.datatable-table > thead > tr > td,
.datatable-table > thead > tr > th,
.datatable-table > tbody > tr > td,
.datatable-table > tbody > tr > th {
  padding: 0.75rem 0.75rem;
  vertical-align: middle;
}
.dataTable-table > tfoot > tr > th,
.dataTable-table > thead > tr > th,
.dataTable-table > tbody > tr > th,
.datatable-table > tfoot > tr > th,
.datatable-table > thead > tr > th,
.datatable-table > tbody > tr > th {
  border-bottom: none;
}

.dataTable-sorter::before,
.datatable-sorter::before {
  border-top: 4px solid var(--bs-border-color);
  opacity: 1;
}

.dataTable-sorter::after,
.datatable-sorter::after {
  border-bottom: 4px solid var(--bs-border-color);
  opacity: 1;
}

.dataTable-sorter::before {
  bottom: 0;
}

.datatable-editor-action .mdi {
  margin-right: 5px;
  color: #95a0c5;
}

.dataTable-selector,
.datatable-selector {
  background-color: var(--bs-card-bg);
  color: var(--bs-body-color);
  border-color: var(--bs-border-color);
  border-radius: 4px;
}
.dataTable-selector:focus-visible,
.datatable-selector:focus-visible {
  outline: none;
}

.datatable-editor-input,
.dataTable-input,
.datatable-input {
  background-color: var(--bs-card-bg);
  color: var(--bs-body-color);
  border: 1px solid var(--bs-border-color);
  border-radius: 4px;
}
.datatable-editor-input:focus,
.dataTable-input:focus,
.datatable-input:focus {
  border-color: #005b6f;
}
.datatable-editor-input:focus-visible,
.dataTable-input:focus-visible,
.datatable-input:focus-visible {
  outline: none;
}

.datatable-wrapper.no-footer .datatable-container {
  border-bottom: 1px solid var(--bs-border-color);
}

.datatable-pagination a,
.datatable-pagination button {
  margin-left: 0;
}
.datatable-pagination .datatable-active a,
.datatable-pagination .datatable-active button,
.datatable-pagination .datatable-active a:focus,
.datatable-pagination .datatable-active a:hover,
.datatable-pagination .datatable-active button:focus,
.datatable-pagination .datatable-active button:hover {
  color: #ffffff;
  background-color: #005b6f;
  border-color: #005b6f;
}
.datatable-pagination a:hover,
.datatable-pagination button:hover {
  color: var(--bs-link-hover-color);
  background-color: var(--bs-tertiary-bg);
  border-color: var(--bs-border-color);
}

.datatable-pagination-list-item .datatable-pagination-list-item-link {
  position: relative;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 0.812rem;
  color: var(--bs-body-color);
  background-color: var(--bs-card-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.datatable-pagination-list-item:first-child .datatable-pagination-list-item-link {
  border-top-left-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.datatable-pagination-list-item:last-child .datatable-pagination-list-item-link {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.datatable-table th button,
.datatable-pagination-list button {
  color: var(--bs-body-color);
}

.checkbox-all thead tr th:first-child button::after, .checkbox-all thead tr th:first-child button::before, .checkbox-all thead tr th:last-child button::after, .checkbox-all thead tr th:last-child button::before {
  display: none;
}

.dataTable-pagination a {
  position: relative;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 0.812rem;
  color: var(--bs-body-color);
  background-color: var(--bs-card-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  margin: 0;
}
.dataTable-pagination a:hover {
  color: var(--bs-link-hover-color);
  background-color: var(--bs-tertiary-bg);
  border-color: var(--bs-border-color);
}
.dataTable-pagination .active a,
.dataTable-pagination .active a:focus,
.dataTable-pagination .active a:hover {
  color: #ffffff;
  background-color: #005b6f;
  border-color: #005b6f;
}
.dataTable-pagination li:first-child a {
  border-top-left-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}
.dataTable-pagination li:last-child a {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.dataTable-wrapper.no-footer .dataTable-container {
  border-bottom: 1px solid var(--bs-border-color);
}

.datatable-editor-menu {
  background-color: var(--bs-card-bg);
}
.datatable-editor-menu a {
  display: flex;
  align-items: center;
}
.datatable-editor-menu a:hover {
  color: var(--bs-link-hover-color);
  background-color: var(--bs-tertiary-bg);
  border-color: var(--bs-border-color);
}

.datatable-editor-separator {
  border-bottom: 1px solid var(--bs-border-color);
  margin: 0;
}

.datatable-table thead th:first-of-type .datatable-sorter:has(.form-check)::before, .datatable-table thead th:first-of-type .datatable-sorter:has(.form-check)::after {
  content: none;
}

html[data-bs-theme=dark] .datatable-container thead,
html[data-bs-theme=dark] .table-light {
  --bs-table-color: #ffffff;
  --bs-table-bg: #272a27;
  --bs-table-border-color: #40424f;
  --bs-table-striped-bg: #363845;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #40424f;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #3b3d4a;
  --bs-table-hover-color: #ffffff;
}

.custom-tooltip {
  --bs-tooltip-bg: var(--bs-purple);
  --bs-tooltip-color: var(--bs-white);
}

.custom-popover {
  --bs-popover-max-width: 200px;
  --bs-popover-border-color: var(--bs-purple);
  --bs-popover-header-bg: var(--bs-purple);
  --bs-popover-header-color: var(--bs-white);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 0.5rem;
}

.nav-tabs-custom .nav.nav-tabs {
  border-bottom: none;
  display: inline-flex;
}
.nav-tabs-custom .nav.nav-tabs .nav-item {
  border: 2px solid rgba(168, 181, 209, 0.2);
  border-radius: 4px;
  margin-right: 5px;
}
.nav-tabs-custom .nav.nav-tabs .nav-link {
  border-radius: 3px;
  padding: 10px 14px;
}
.nav-tabs-custom .nav.nav-tabs .nav-link.active {
  background-color: rgba(0, 91, 111, 0.08);
  border-color: #005b6f;
  color: #005b6f;
}
.nav-tabs-custom .nav.nav-tabs .nav-link.active i {
  color: #005b6f;
}
.nav-tabs-custom .nav.nav-tabs .nav-link i {
  font-size: 24px;
  vertical-align: middle;
  color: #95a0c5;
}

.tooltip-primary {
  --bs-tooltip-bg: var(--bs-primary);
}

.tooltip-secondary {
  --bs-tooltip-bg: var(--bs-secondary);
}

.tooltip-success {
  --bs-tooltip-bg: var(--bs-success);
}

.tooltip-info {
  --bs-tooltip-bg: var(--bs-info);
}

.tooltip-warning {
  --bs-tooltip-bg: var(--bs-warning);
}

.tooltip-danger {
  --bs-tooltip-bg: var(--bs-danger);
}

.tooltip-pink {
  --bs-tooltip-bg: var(--bs-pink);
}

.tooltip-purple {
  --bs-tooltip-bg: var(--bs-purple);
}

.tooltip-blue {
  --bs-tooltip-bg: var(--bs-blue);
}

.tooltip-light {
  --bs-tooltip-bg: var(--bs-light);
}

.tooltip-dark {
  --bs-tooltip-bg: var(--bs-dark);
}

.nav-tabs.nav-tabs-custom {
  border-bottom: 1px solid var(--bs-border-color);
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: transparent;
  border-bottom: 1px solid #005b6f !important;
}
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

.h-80 {
  height: 300px;
}

.ms-45 {
  margin-left: 45px;
}

.border-dashed {
  border: 1px dashed;
}

.border-theme-color {
  border-color: var(--bs-border-color);
}

.border-dashed-bottom {
  border-bottom: 1px dashed var(--bs-border-color);
}

.border-dashed-top {
  border-top: 1px dashed var(--bs-border-color);
}

.border-body-color {
  border-color: #f7f9fb;
}

.border-card-bg {
  border-color: var(--bs-secondary-bg) !important;
}

.border-secondary {
  border-color: var(--bs-border-secondary) !important;
}

.bg-blue-subtle {
  background-color: var(--bs-blue-bg-subtle);
}

.bg-pink-subtle {
  background-color: var(--bs-pink-bg-subtle);
}

.bg-purple-subtle {
  background-color: var(--bs-purple-bg-subtle);
}

.cursor-pointer {
  cursor: pointer;
}

.thumb-xs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px !important;
  width: 20px !important;
  font-size: 10px;
  font-weight: 700;
}

.thumb-sm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px !important;
  width: 24px !important;
  font-size: 12px;
  font-weight: 700;
}

.thumb-md {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  font-size: 14px;
  font-weight: 700;
}

.thumb-lg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  font-size: 18px;
  font-weight: 700;
}

.thumb-xl {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  font-size: 24px;
  font-weight: 700;
}

.thumb-xxl {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  font-size: 32px;
  font-weight: 700;
}

.card-bg {
  background-color: var(--bs-secondary-bg);
}

.text-card-bg {
  color: var(--bs-secondary-bg);
}

.flex-basis-0 {
  flex-basis: 0;
}

hr.hr-dashed {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px dashed var(--bs-border-color);
  height: 0;
  overflow: visible;
  opacity: 1;
}

.error {
  color: var(--bs-danger);
}

[class*=" iconoir-"]::before,
[class^=iconoir-]::before {
  stroke-width: 8px;
}

.icon-demo-content {
  text-align: center;
}
.icon-demo-content i {
  display: block;
  font-size: 30px;
  margin: 0 auto 5px;
  color: #a8b5d1;
}
.icon-demo-content svg {
  display: block;
  font-size: 30px;
  margin: 0 auto 5px;
  color: #95a0c5;
}
.icon-demo-content .col-sm-6 {
  margin-bottom: 40px;
}
.icon-demo-content .col-sm-6:hover i {
  color: #005b6f;
}
.icon-demo-content .col-sm-6:hover path:nth-of-type(1) {
  fill: #005b6f;
}
.icon-demo-content .col-sm-6:hover path:nth-of-type(2) {
  fill: var(--bs-secondary-color);
}

.startbar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--bs-startbar-width);
  background-color: var(--bs-startbar-bg);
  transition: 0.3s;
  z-index: 1005;
  box-shadow: 0 3px 16px rgba(142, 134, 171, 0.05);
}
.startbar .brand {
  display: flex;
  position: sticky;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4px;
  width: 100%;
  height: var(--bs-topbar-height);
  transition: 0.3s;
}
.startbar .brand .logo-sm {
  height: 38px;
}
.startbar .brand .logo-lg {
  transition: 0.3s;
  margin-left: 4px;
}
.startbar .startbar-menu {
  padding-bottom: var(--bs-topbar-height);
  height: 100vh;
}
.startbar .startbar-menu .startbar-collapse {
  padding: 0 16px 16px;
  height: calc(100vh - 120px);
  overflow-y: auto;
}
.startbar .startbar-menu .navbar-nav .menu-label {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: var(--bs-menu-label-color);
  padding: 14px 8px 14px 20px;
  text-align: start;
  letter-spacing: 1px;
}
.startbar .startbar-menu .navbar-nav .menu-label small {
  display: none;
}
.startbar .startbar-menu .navbar-nav .nav-item .nav-link {
  position: relative;
  color: var(--bs-menu-link-color);
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px 16px;
  line-height: 20px;
  margin-bottom: 3px;
  border: 1px solid transparent;
}
.startbar .startbar-menu .navbar-nav .nav-item .nav-link:hover, .startbar .startbar-menu .navbar-nav .nav-item .nav-link.active {
  color: #005b6f;
}
.startbar .startbar-menu .navbar-nav .nav-item .nav-link:hover::before, .startbar .startbar-menu .navbar-nav .nav-item .nav-link.active::before {
  border-color: #005b6f !important;
  background-color: #005b6f !important;
}
.startbar .startbar-menu .navbar-nav .nav-item .nav-link::before {
  content: "";
  border: 2px solid transparent;
  position: absolute;
  left: -12px;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  width: 6px;
  background-color: transparent;
}
.startbar .startbar-menu .navbar-nav .nav-item .nav-link[data-bs-toggle=collapse]:after {
  display: block;
  content: "\f105";
  font-family: "Line Awesome Free";
  font-weight: 900;
  margin-left: auto;
  line-height: 20px;
  transition: transform 0.2s;
}
.startbar .startbar-menu .navbar-nav .nav-item .nav-link[data-bs-toggle=collapse][aria-expanded=true] {
  background-color: rgba(0, 91, 111, 0.05);
  border-radius: 10px;
}
.startbar .startbar-menu .navbar-nav .nav-item .nav-link[data-bs-toggle=collapse][aria-expanded=true]:after {
  transform: rotate(90deg);
  color: #005b6f;
}
.startbar .startbar-menu .navbar-nav .nav-item .nav-link[data-bs-toggle=collapse][aria-expanded=true] i,
.startbar .startbar-menu .navbar-nav .nav-item .nav-link[data-bs-toggle=collapse][aria-expanded=true] span {
  color: #005b6f;
  font-weight: 600;
}
.startbar .startbar-menu .navbar-nav .nav-item .nav-link[data-bs-toggle=collapse][aria-expanded=true]::before {
  content: "";
  border: 2px solid #005b6f;
  background-color: #005b6f;
}
.startbar .startbar-menu .navbar-nav .nav-item .nav .nav-item {
  padding-left: 12px;
}
.startbar .startbar-menu .navbar-nav .nav-item .nav .nav-item .nav-link {
  padding: 6px 16px 6px 40px;
}
.startbar .startbar-menu .navbar-nav .nav-item .nav .nav-item .nav-link:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border: 1px solid var(--bs-nav-link-disc-color);
  border-radius: 50%;
  background: rgba(137, 151, 189, 0.2);
  left: 12px;
  top: auto;
  bottom: auto;
}
.startbar .startbar-menu .menu-icon {
  font-size: 20px;
  margin-right: 16px;
  color: var(--bs-menu-icon-color);
}

.logo-light {
  display: none;
}

.logo-dark {
  display: inline-block;
}

html[data-startbar=dark] .logo-light,
html[data-bs-theme=dark] .logo-light {
  display: inline-block;
}
html[data-startbar=dark] .logo-dark,
html[data-bs-theme=dark] .logo-dark {
  display: none;
}

@media (min-width: 1200px) {
  body[data-sidebar-size=collapsed] .startbar:not(:hover) {
    width: var(--bs-startbar-collapsed-width);
    transition: 0.3s;
  }
  body[data-sidebar-size=collapsed] .startbar:not(:hover) .brand {
    width: var(--bs-startbar-collapsed-width);
  }
  body[data-sidebar-size=collapsed] .startbar:not(:hover) .brand .logo {
    display: flex;
  }
  body[data-sidebar-size=collapsed] .startbar:not(:hover) .brand .logo .logo-lg {
    width: 0;
    margin-left: 0;
    transition: 0.3s;
  }
  body[data-sidebar-size=collapsed] .startbar:not(:hover) .startbar-menu .navbar-nav .menu-label {
    text-align: center;
    font-weight: 600;
    padding: 0;
  }
  body[data-sidebar-size=collapsed] .startbar:not(:hover) .startbar-menu .navbar-nav .menu-label span {
    display: none;
  }
  body[data-sidebar-size=collapsed] .startbar:not(:hover) .startbar-menu .navbar-nav .menu-label small {
    display: inline-block;
  }
  body[data-sidebar-size=collapsed] .startbar:not(:hover) .startbar-menu .navbar-nav .nav-item .nav-link {
    justify-content: center;
    padding: 10px 18px;
  }
  body[data-sidebar-size=collapsed] .startbar:not(:hover) .startbar-menu .navbar-nav .nav-item .nav-link span {
    display: none;
  }
  body[data-sidebar-size=collapsed] .startbar:not(:hover) .startbar-menu .navbar-nav .nav-item .nav-link[data-bs-toggle=collapse]:after, body[data-sidebar-size=collapsed] .startbar:not(:hover) .startbar-menu .navbar-nav .nav-item .nav-link[data-bs-toggle=true]:after {
    display: none;
  }
  body[data-sidebar-size=collapsed] .startbar:not(:hover) .startbar-menu .navbar-nav .nav-item .nav-link .menu-icon {
    margin-right: 0;
  }
  body[data-sidebar-size=collapsed] .startbar:not(:hover) .startbar-menu .navbar-nav .nav-item .collapse {
    display: none;
  }
  body[data-sidebar-size=collapsed] .startbar:not(:hover) .startbar-menu .starbar-user {
    width: calc(var(--bs-startbar-collapsed-width) - 1px);
    transition: 0.3s;
  }
  body[data-sidebar-size=collapsed] .startbar:not(:hover) .startbar-menu .starbar-user img.thumb-lg {
    height: 40px;
    width: 40px;
  }
  body[data-sidebar-size=collapsed] .startbar:not(:hover) .startbar-menu .starbar-user span {
    display: none !important;
  }
  body[data-sidebar-size=collapsed] .startbar:not(:hover) .update-msg {
    display: none;
  }
  body[data-sidebar-size=collapsed] .page-wrapper .page-content {
    margin-left: var(--bs-startbar-collapsed-width);
  }
}
.page-wrapper .page-content {
  transition: 0.3s;
}

@media (max-width: 1199.98px) {
  body[data-sidebar-size=collapsed] .startbar {
    margin-left: calc(var(--bs-startbar-width) * -1);
  }
  .page-wrapper .page-content {
    margin-left: 0;
  }
  body[data-sidebar-size=default] .brand {
    width: var(--bs-startbar-width);
  }
  body[data-sidebar-size=default] .brand .logo {
    display: block;
  }
  body[data-sidebar-size=default] .brand .logo .logo-lg {
    width: auto;
  }
  body[data-sidebar-size=default] .startbar-menu .navbar-nav .menu-label {
    text-align: start;
    border-color: transparent;
    font-weight: 400;
  }
  body[data-sidebar-size=default] .startbar-menu .navbar-nav .menu-label span {
    display: inline-block;
  }
  body[data-sidebar-size=default] .startbar-menu .navbar-nav .menu-label small {
    display: none;
  }
  body[data-sidebar-size=default] .startbar-menu .navbar-nav .nav-item .nav-link {
    justify-content: start;
  }
  body[data-sidebar-size=default] .startbar-menu .navbar-nav .nav-item .nav-link span {
    display: inline-block;
  }
  body[data-sidebar-size=default] .startbar-menu .navbar-nav .nav-item .nav-link[data-bs-toggle=collapse]:after, body[data-sidebar-size=default] .startbar-menu .navbar-nav .nav-item .nav-link[data-bs-toggle=true]:after {
    display: inline-block;
  }
  body[data-sidebar-size=default] .startbar-menu .navbar-nav .nav-item .nav-link .menu-icon {
    margin-right: 8px;
  }
  body[data-sidebar-size=default] .page-wrapper .page-content {
    width: 100%;
    transition: 0.3s;
    margin-left: 0;
  }
}
.update-msg {
  border-radius: 5px;
  padding: 1.5rem 12px;
  margin: 50px 16px 16px;
  position: relative;
  background-color: rgba(0, 91, 111, 0.08);
}
.update-msg h5 {
  color: var(--bs-menu-link-color);
  font-weight: 700;
}
.update-msg .update-icon-box {
  background-color: var(--bs-startbar-bg);
  border: 5px solid rgba(0, 91, 111, 0.08);
  margin-top: -40px;
}
.update-msg a.btn {
  background-color: var(--bs-startbar-bg);
}

.startbar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(33, 37, 41, 0.35);
  z-index: 1003;
  display: none;
}

.startbar-enable .startbar-overlay {
  display: block;
}

@media (max-width: 1199.98px) {
  [data-sidebar-size=default] .startbar-overlay {
    display: block;
  }
}
[dir=rtl] .startbar .nav-link[data-bs-toggle=collapse]:after {
  transform: rotate(180deg);
}

.label-border {
  margin: 12px 0;
}
.label-border .border_left {
  display: inline;
  position: relative;
}
.label-border .border_left:before {
  content: "";
  display: inline-block;
  border-bottom: 2px solid rgba(0, 91, 111, 0.2);
  width: 20px;
  position: relative;
  top: -7px;
}
.label-border .border_left:after {
  content: "";
  display: inline-block;
  border-bottom: 2px solid rgba(0, 91, 111, 0.5);
  width: 6px;
  position: absolute;
  top: 7px;
  right: 0;
}
.label-border .border_right {
  display: inline;
  position: relative;
}
.label-border .border_right:before {
  content: "";
  display: inline-block;
  border-bottom: 2px solid rgba(0, 91, 111, 0.2);
  width: 20px;
  position: relative;
  top: -1px;
}
.label-border .border_right:after {
  content: "";
  display: inline-block;
  border-bottom: 2px solid rgba(0, 91, 111, 0.5);
  width: 6px;
  position: absolute;
  bottom: 10px;
  left: 0;
}

.topbar {
  height: var(--bs-topbar-height);
  width: calc(100% - var(--bs-startbar-width));
  margin-left: auto;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: 0.3s;
}
.topbar .topbar-custom {
  background-color: transparent;
  transition: 0.3s;
}
.topbar .topbar-custom.nav-sticky {
  background-color: var(--bs-body-bg);
  box-shadow: 0 3px 4px rgba(142, 134, 171, 0.1);
  border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius);
  transition: 0.3s;
}
.topbar .topbar-custom.nav-sticky .nav-link.mobile-menu-btn {
  margin-left: 16px;
  transition: 0.3s;
}
.topbar .topbar-custom.nav-sticky:last-child ul li:last-child .nav-icon {
  margin-right: 16px;
  transition: 0.3s;
}
.topbar .topbar-custom:last-child ul li:last-child .nav-icon {
  margin-right: 0;
  transition: 0.3s;
}
.topbar .topbar-custom .topbar-item {
  height: var(--bs-topbar-height);
  display: flex;
  align-items: center;
  transition: 0.3s;
}
.topbar .topbar-custom .nav-link {
  margin: 0 8px;
}
.topbar .topbar-custom .nav-link.mobile-menu-btn {
  margin-left: 0;
  transition: 0.3s;
}
.topbar .topbar-custom .nav-link.nav-icon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--bs-topbar-bg);
  box-shadow: 0 3px 16px rgba(142, 134, 171, 0.05);
}
.topbar .topbar-custom .nav-link.nav-icon i {
  font-size: 20px;
  padding-bottom: 2px;
  color: var(--bs-topbar-nav-icon-color);
}
.topbar .app-search {
  position: relative;
  margin: 0;
}
.topbar .app-search form {
  position: relative;
  z-index: 1;
}
.topbar .app-search form .form-control,
.topbar .app-search form .form-control:focus {
  border: 1px solid transparent;
  font-size: 13px;
  height: 48px;
  background: var(--bs-topbar-bg);
  box-shadow: 0 3px 16px rgba(142, 134, 171, 0.05);
  width: 300px;
  border-radius: 30px;
  padding-left: 48px;
  margin: 0 8px;
}
.topbar .app-search form button {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 16px;
  top: 50%;
  font-size: 22px;
  transform: translateY(-50%);
  border: none;
  text-align: center;
  background-color: transparent;
  color: var(--bs-topbar-nav-icon-color);
}
.topbar .app-search form button i {
  display: flex;
}
.topbar .app-search form button:focus {
  outline: none;
}
.topbar .app-search ::placeholder {
  color: var(--bs-topbar-nav-icon-color);
  font-weight: 400;
}
.topbar .app-search input.form-control:-moz-placeholder {
  color: var(--bs-topbar-nav-icon-color);
}
.topbar .app-search input.form-control::-moz-placeholder {
  color: var(--bs-topbar-nav-icon-color);
}
.topbar .app-search input.form-control:-ms-input-placeholder {
  color: var(--bs-topbar-nav-icon-color);
}

.topbar .topbar-custom.nav-sticky .topbar-item,
.topbar:has(.nav-sticky) {
  height: calc(var(--bs-topbar-height) - 20px);
  transition: 0.3s;
}

html[data-bs-theme=dark] .topbar .topbar-custom .nav-link.nav-icon .light-mode {
  display: none;
}
html[data-bs-theme=dark] .topbar .topbar-custom .nav-link.nav-icon .dark-mode {
  display: inline-block;
}

html[data-bs-theme=light] .topbar .topbar-custom .nav-link.nav-icon .light-mode {
  display: inline-block;
}
html[data-bs-theme=light] .topbar .topbar-custom .nav-link.nav-icon .dark-mode {
  display: none;
}

@media (min-width: 1023.98px) {
  body[data-sidebar-size=collapsed] .topbar {
    width: calc(100% - var(--bs-startbar-collapsed-width));
  }
  .welcome-text {
    display: inline-block;
  }
}
@media (max-width: 1024px) {
  body[data-sidebar-size=collapsed] .topbar,
  body[data-sidebar-size=default] .topbar {
    width: 100%;
  }
  .welcome-text {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .hide-phone {
    display: none !important;
  }
  .topbar .topbar-custom .nav-link {
    margin: 0 4px;
  }
  .topbar .topbar-custom.nav-sticky .nav-link.mobile-menu-btn {
    margin-left: 4px;
  }
  .topbar .topbar-custom.nav-sticky:last-child ul li:last-child .nav-icon {
    margin-right: 4px;
  }
}
@media (max-width: 380px) {
  .topbar .topbar-custom {
    margin: 0;
  }
}
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 36px;
  text-align: center;
  padding: 16px 0 8px;
  border: 1px solid transparent;
  color: var(--bs-body-color);
}
.dropdown-icon-item img {
  height: 36px;
}
.dropdown-icon-item span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}
.dropdown-icon-item:hover {
  background-color: #fff;
}

/*
Template Name: Rizz - Admin & Dashboard Template
Author: Mannatthemes
Version: 2.2.0
File: footer Css
*/
.footer {
  bottom: 0;
  position: absolute;
  right: 0;
  left: 0;
  background-color: var(--bs-body-bg);
}

.chat-box-left {
  float: left;
  width: 340px;
  height: 710px;
  background-color: var(--bs-secondary-bg);
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
}
.chat-box-left .chat-body-left {
  height: 556px;
}

.chat-box-right {
  width: auto;
  background-color: var(--bs-body-bg);
  display: block;
  border-radius: 8px;
  position: relative;
  height: 710px;
  margin-left: 361px;
  margin-bottom: 20px;
}
.chat-box-right .chat-body {
  padding: 16px;
  height: 540px;
}
.chat-box-right .chat-body .chat-detail {
  max-height: 610px;
}
.chat-box-right .chat-body .chat-detail .chat-box .user-chat p {
  background-color: var(--bs-secondary-bg);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 16px 16px 16px 0;
  padding: 14px;
  margin-bottom: 4px;
  width: 75%;
  max-width: max-content;
}
.chat-box-right .chat-body .chat-detail .chat-box.reverse {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.chat-box-right .chat-body .chat-detail .chat-box.reverse .user-chat {
  display: contents;
  width: 100%;
}
.chat-box-right .chat-body .chat-detail .chat-box.reverse .user-chat p {
  background-color: #005b6f;
  color: #ffffff;
  border-radius: 16px 16px 0 16px;
  text-align: end;
}
.chat-box-right .chat-body .chat-detail .chat-box .chat-time {
  font-size: 10px;
}
.chat-box-right .chat-footer {
  background-color: var(--bs-secondary-bg);
  border-radius: 8px;
  padding: 16px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
.chat-box-right .chat-footer .chat-features a {
  color: #c1cde0;
  font-size: 22px;
  margin-left: 12px;
}
.chat-box-right .chat-footer .chat-features a:hover {
  color: #005b6f;
}
.chat-box-right .chat-footer input.form-control {
  border: none;
}

@media (min-width: 1024px) and (max-width: 1680px) {
  .chat-box-left {
    width: 310px;
    float: left;
  }
  .chat-box-right {
    width: auto;
    margin-left: 330px;
  }
}
@media (min-width: 767px) and (max-width: 1023.98px) {
  .chat-box-left {
    width: 100%;
    float: none;
  }
  .chat-box-right {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .chat-box-left {
    float: none;
    width: 100%;
  }
  .chat-box-right {
    margin: 0;
    width: 100%;
  }
}
@media (max-width: 660px) {
  .chat-box-left {
    float: none;
    width: 100%;
  }
  .chat-box-right {
    margin: 0;
    width: 100%;
  }
}
@media (max-width: 568px) {
  .chat-box-left {
    float: none;
    width: 100%;
  }
  .chat-box-right {
    margin: 0;
    width: 100%;
  }
}
.kanban-board {
  margin-bottom: 0.75rem;
  position: relative;
  display: flex;
  align-items: stretch;
  overflow-x: auto;
  padding-bottom: 1rem;
}
.kanban-board .kanban-col {
  margin-inline-end: 1.5rem;
  position: relative;
  width: 100%;
  min-width: 20rem;
}
.kanban-board .kanban-col:last-child {
  margin-inline-end: 0;
}
.kanban-board .kanban-col .add-btn {
  display: none;
  position: relative;
}
.kanban-board .kanban-col:hover .add-btn {
  display: inline-block;
}
.kanban-board .kanban-col .card {
  cursor: grab;
}

.card.gu-mirror {
  cursor: grabbing;
}

.pricing-content li {
  margin-bottom: 14px;
  font-weight: 500;
  color: #7a82b1;
}
.pricing-content li:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  font-size: 9px;
  text-align: center;
  background-color: rgba(0, 91, 111, 0.1);
  color: #005b6f;
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
}

.animate-blink {
  -webkit-animation: a-animate-blink 1s step-start 0s infinite;
  -moz-animation: a-animate-blink 1s step-start 0s infinite;
  -ms-animation: a-animate-blink 1s step-start 0s infinite;
  -o-animation: a-animate-blink 1s step-start 0s infinite;
  animation: a-animate-blink 1s step-start 0s infinite;
  animation-fill-mode: initial;
}

@-webkit-keyframes a-animate-blink {
  50% {
    opacity: 0;
  }
}
@-moz-keyframes a-animate-blink {
  50% {
    opacity: 0;
  }
}
@-o-keyframes a-animate-blink {
  50% {
    opacity: 0;
  }
}
@keyframes a-animate-blink {
  50% {
    opacity: 0;
  }
}
@media print {
  .page-wrapper .page-content {
    width: 100%;
    margin-top: 0;
  }
}
.starability-basic,
.starability-growRotate,
.starability-grow,
.starability-fade,
.starability-slot,
.starability-checkmark {
  min-height: auto;
}
.starability-basic > input:checked + label,
.starability-basic > input:focus + label,
.starability-growRotate > input:checked + label,
.starability-growRotate > input:focus + label,
.starability-grow > input:checked + label,
.starability-grow > input:focus + label,
.starability-fade > input:checked + label,
.starability-fade > input:focus + label,
.starability-slot > input:checked + label,
.starability-slot > input:focus + label,
.starability-checkmark > input:checked + label,
.starability-checkmark > input:focus + label {
  outline: none;
  text-decoration: none;
}

.ribbon1 {
  position: absolute;
  top: -6.1px;
  right: 10px;
}
.ribbon1:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 10px solid;
}
.ribbon1 span {
  display: block;
  padding: 6px 4px 0px;
  border-top-right-radius: 6px;
  width: 60px;
  font-size: 11px;
  font-weight: 500;
}
.ribbon1 span:before, .ribbon1 span:after {
  position: absolute;
  content: "";
}
.ribbon1 span:before {
  height: 6px;
  width: 4px;
  left: -4px;
  top: 0;
}
.ribbon1 span:after {
  height: 6px;
  width: 6px;
  left: -6px;
  top: 0;
  border-radius: 8px 8px 0 0;
}
.ribbon1 span.rib1-primary, .ribbon1 span:before {
  background: #005b6f;
}
.ribbon1 span.rib1-primary:after {
  background: #001c23;
}
.ribbon1 span.rib1-secondary, .ribbon1 span:before {
  background: #95a0c5;
}
.ribbon1 span.rib1-secondary:after {
  background: #6474aa;
}
.ribbon1 span.rib1-success, .ribbon1 span:before {
  background: #22c5ad;
}
.ribbon1 span.rib1-success:after {
  background: #178474;
}
.ribbon1 span.rib1-info, .ribbon1 span:before {
  background: #41cbd8;
}
.ribbon1 span.rib1-info:after {
  background: #239eaa;
}
.ribbon1 span.rib1-warning, .ribbon1 span:before {
  background: #ff9f43;
}
.ribbon1 span.rib1-warning:after {
  background: #f67800;
}
.ribbon1 span.rib1-danger, .ribbon1 span:before {
  background: #ef4d56;
}
.ribbon1 span.rib1-danger:after {
  background: #dc141f;
}
.ribbon1 span.rib1-pink, .ribbon1 span:before {
  background: #fd3c97;
}
.ribbon1 span.rib1-pink:after {
  background: #ea0270;
}
.ribbon1 span.rib1-purple, .ribbon1 span:before {
  background: #7367f0;
}
.ribbon1 span.rib1-purple:after {
  background: #3321e9;
}
.ribbon1 span.rib1-blue, .ribbon1 span:before {
  background: #0d6efd;
}
.ribbon1 span.rib1-blue:after {
  background: #024dbc;
}
.ribbon1 span.rib1-light, .ribbon1 span:before {
  background: #f4f6f9;
}
.ribbon1 span.rib1-light:after {
  background: #c3cede;
}
.ribbon1 span.rib1-dark, .ribbon1 span:before {
  background: #2b2d3b;
}
.ribbon1 span.rib1-dark:after {
  background: #0b0b0f;
}
.ribbon1.rib1-primary:after {
  border-top-color: #005b6f;
}
.ribbon1.rib1-secondary:after {
  border-top-color: #95a0c5;
}
.ribbon1.rib1-success:after {
  border-top-color: #22c5ad;
}
.ribbon1.rib1-info:after {
  border-top-color: #41cbd8;
}
.ribbon1.rib1-warning:after {
  border-top-color: #ff9f43;
}
.ribbon1.rib1-danger:after {
  border-top-color: #ef4d56;
}
.ribbon1.rib1-pink:after {
  border-top-color: #fd3c97;
}
.ribbon1.rib1-purple:after {
  border-top-color: #7367f0;
}
.ribbon1.rib1-blue:after {
  border-top-color: #0d6efd;
}
.ribbon1.rib1-light:after {
  border-top-color: #f4f6f9;
}
.ribbon1.rib1-dark:after {
  border-top-color: #2b2d3b;
}

.ribbon2 {
  width: 30px;
  padding: 8px 0;
  position: absolute;
  top: -6px;
  right: 12px;
  text-align: center;
  border-top-left-radius: 3px;
}
.ribbon2:before, .ribbon2:after {
  content: "";
  position: absolute;
}
.ribbon2:before {
  height: 0;
  width: 0;
  right: -5.5px;
  top: 0.1px;
  border-right: 6px solid transparent;
}
.ribbon2:after {
  height: 0;
  width: 0;
  bottom: -18px;
  left: 0;
  border-bottom: 18px solid transparent;
}
.ribbon2.rib2-primary {
  background: #005b6f;
}
.ribbon2.rib2-primary:before {
  border-bottom: 6px solid #001c23;
}
.ribbon2.rib2-primary:after {
  border-left: 15px solid #005b6f;
  border-right: 15px solid #005b6f;
}
.ribbon2.rib2-secondary {
  background: #95a0c5;
}
.ribbon2.rib2-secondary:before {
  border-bottom: 6px solid #6474aa;
}
.ribbon2.rib2-secondary:after {
  border-left: 15px solid #95a0c5;
  border-right: 15px solid #95a0c5;
}
.ribbon2.rib2-success {
  background: #22c5ad;
}
.ribbon2.rib2-success:before {
  border-bottom: 6px solid #178474;
}
.ribbon2.rib2-success:after {
  border-left: 15px solid #22c5ad;
  border-right: 15px solid #22c5ad;
}
.ribbon2.rib2-info {
  background: #41cbd8;
}
.ribbon2.rib2-info:before {
  border-bottom: 6px solid #239eaa;
}
.ribbon2.rib2-info:after {
  border-left: 15px solid #41cbd8;
  border-right: 15px solid #41cbd8;
}
.ribbon2.rib2-warning {
  background: #ff9f43;
}
.ribbon2.rib2-warning:before {
  border-bottom: 6px solid #f67800;
}
.ribbon2.rib2-warning:after {
  border-left: 15px solid #ff9f43;
  border-right: 15px solid #ff9f43;
}
.ribbon2.rib2-danger {
  background: #ef4d56;
}
.ribbon2.rib2-danger:before {
  border-bottom: 6px solid #dc141f;
}
.ribbon2.rib2-danger:after {
  border-left: 15px solid #ef4d56;
  border-right: 15px solid #ef4d56;
}
.ribbon2.rib2-pink {
  background: #fd3c97;
}
.ribbon2.rib2-pink:before {
  border-bottom: 6px solid #ea0270;
}
.ribbon2.rib2-pink:after {
  border-left: 15px solid #fd3c97;
  border-right: 15px solid #fd3c97;
}
.ribbon2.rib2-purple {
  background: #7367f0;
}
.ribbon2.rib2-purple:before {
  border-bottom: 6px solid #3321e9;
}
.ribbon2.rib2-purple:after {
  border-left: 15px solid #7367f0;
  border-right: 15px solid #7367f0;
}
.ribbon2.rib2-blue {
  background: #0d6efd;
}
.ribbon2.rib2-blue:before {
  border-bottom: 6px solid #024dbc;
}
.ribbon2.rib2-blue:after {
  border-left: 15px solid #0d6efd;
  border-right: 15px solid #0d6efd;
}
.ribbon2.rib2-light {
  background: #f4f6f9;
}
.ribbon2.rib2-light:before {
  border-bottom: 6px solid #c3cede;
}
.ribbon2.rib2-light:after {
  border-left: 15px solid #f4f6f9;
  border-right: 15px solid #f4f6f9;
}
.ribbon2.rib2-dark {
  background: #2b2d3b;
}
.ribbon2.rib2-dark:before {
  border-bottom: 6px solid #0b0b0f;
}
.ribbon2.rib2-dark:after {
  border-left: 15px solid #2b2d3b;
  border-right: 15px solid #2b2d3b;
}

.ribbon3 {
  text-align: center;
  width: 70px;
  height: 26px;
  line-height: 26px;
  padding-right: 12px;
  position: absolute;
  right: -8px;
  top: 10px;
  font-size: 11px;
  font-weight: 500;
}
.ribbon3:before, .ribbon3:after {
  content: "";
  position: absolute;
}
.ribbon3:before {
  height: 0;
  width: 0;
  bottom: -8.5px;
  right: 0.1px;
  border-right: 9px solid transparent;
}
.ribbon3:after {
  height: 0;
  width: 0;
  left: -14.5px;
  border-bottom: 13px solid transparent;
  border-top: 13px solid transparent;
}
.ribbon3.rib3-primary {
  background: #005b6f;
}
.ribbon3.rib3-primary:before {
  border-top: 9px solid #001c23;
}
.ribbon3.rib3-primary:after {
  border-right: 15px solid #005b6f;
}
.ribbon3.rib3-secondary {
  background: #95a0c5;
}
.ribbon3.rib3-secondary:before {
  border-top: 9px solid #6474aa;
}
.ribbon3.rib3-secondary:after {
  border-right: 15px solid #95a0c5;
}
.ribbon3.rib3-success {
  background: #22c5ad;
}
.ribbon3.rib3-success:before {
  border-top: 9px solid #178474;
}
.ribbon3.rib3-success:after {
  border-right: 15px solid #22c5ad;
}
.ribbon3.rib3-info {
  background: #41cbd8;
}
.ribbon3.rib3-info:before {
  border-top: 9px solid #239eaa;
}
.ribbon3.rib3-info:after {
  border-right: 15px solid #41cbd8;
}
.ribbon3.rib3-warning {
  background: #ff9f43;
}
.ribbon3.rib3-warning:before {
  border-top: 9px solid #f67800;
}
.ribbon3.rib3-warning:after {
  border-right: 15px solid #ff9f43;
}
.ribbon3.rib3-danger {
  background: #ef4d56;
}
.ribbon3.rib3-danger:before {
  border-top: 9px solid #dc141f;
}
.ribbon3.rib3-danger:after {
  border-right: 15px solid #ef4d56;
}
.ribbon3.rib3-pink {
  background: #fd3c97;
}
.ribbon3.rib3-pink:before {
  border-top: 9px solid #ea0270;
}
.ribbon3.rib3-pink:after {
  border-right: 15px solid #fd3c97;
}
.ribbon3.rib3-purple {
  background: #7367f0;
}
.ribbon3.rib3-purple:before {
  border-top: 9px solid #3321e9;
}
.ribbon3.rib3-purple:after {
  border-right: 15px solid #7367f0;
}
.ribbon3.rib3-blue {
  background: #0d6efd;
}
.ribbon3.rib3-blue:before {
  border-top: 9px solid #024dbc;
}
.ribbon3.rib3-blue:after {
  border-right: 15px solid #0d6efd;
}
.ribbon3.rib3-light {
  background: #f4f6f9;
}
.ribbon3.rib3-light:before {
  border-top: 9px solid #c3cede;
}
.ribbon3.rib3-light:after {
  border-right: 15px solid #f4f6f9;
}
.ribbon3.rib3-dark {
  background: #2b2d3b;
}
.ribbon3.rib3-dark:before {
  border-top: 9px solid #0b0b0f;
}
.ribbon3.rib3-dark:after {
  border-right: 15px solid #2b2d3b;
}

.ribbon4 {
  width: 100%;
  height: 188px;
  position: absolute;
  top: -8px;
  left: 8px;
  overflow: hidden;
}
.ribbon4:before, .ribbon4:after {
  content: "";
  position: absolute;
}
.ribbon4:before {
  width: 40px;
  height: 8px;
  right: 44px;
  border-radius: 8px 8px 0px 0px;
}
.ribbon4:after {
  width: 8px;
  height: 40px;
  right: 0px;
  top: 44px;
  border-radius: 0px 8px 8px 0px;
}
.ribbon4.rib4-primary:after, .ribbon4.rib4-primary:before {
  background: #00313c;
}
.ribbon4.rib4-secondary:after, .ribbon4.rib4-secondary:before {
  background: #7482b3;
}
.ribbon4.rib4-success:after, .ribbon4.rib4-success:before {
  background: #1a9a87;
}
.ribbon4.rib4-info:after, .ribbon4.rib4-info:before {
  background: #27b2bf;
}
.ribbon4.rib4-warning:after, .ribbon4.rib4-warning:before {
  background: #ff8510;
}
.ribbon4.rib4-danger:after, .ribbon4.rib4-danger:before {
  background: #eb1e2a;
}
.ribbon4.rib4-pink:after, .ribbon4.rib4-pink:before {
  background: #fc0a7c;
}
.ribbon4.rib4-purple:after, .ribbon4.rib4-purple:before {
  background: #4839eb;
}
.ribbon4.rib4-blue:after, .ribbon4.rib4-blue:before {
  background: #0257d5;
}
.ribbon4.rib4-light:after, .ribbon4.rib4-light:before {
  background: #d3dbe7;
}
.ribbon4.rib4-dark:after, .ribbon4.rib4-dark:before {
  background: #16171e;
}
.ribbon4 .ribbon4-band {
  width: 170px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  top: 16px;
  right: -50px;
  z-index: 2;
  overflow: hidden;
  transform: rotate(45deg);
  border: 1px dashed;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
}
.ribbon4 .ribbon4-band.ribbon4-band-primary {
  box-shadow: 0 0 0 3px #005b6f, 0px 21px 5px -18px rgba(122, 130, 177, 0.6);
  background: #005b6f;
}
.ribbon4 .ribbon4-band.ribbon4-band-secondary {
  box-shadow: 0 0 0 3px #95a0c5, 0px 21px 5px -18px rgba(122, 130, 177, 0.6);
  background: #95a0c5;
}
.ribbon4 .ribbon4-band.ribbon4-band-success {
  box-shadow: 0 0 0 3px #22c5ad, 0px 21px 5px -18px rgba(122, 130, 177, 0.6);
  background: #22c5ad;
}
.ribbon4 .ribbon4-band.ribbon4-band-info {
  box-shadow: 0 0 0 3px #41cbd8, 0px 21px 5px -18px rgba(122, 130, 177, 0.6);
  background: #41cbd8;
}
.ribbon4 .ribbon4-band.ribbon4-band-warning {
  box-shadow: 0 0 0 3px #ff9f43, 0px 21px 5px -18px rgba(122, 130, 177, 0.6);
  background: #ff9f43;
}
.ribbon4 .ribbon4-band.ribbon4-band-danger {
  box-shadow: 0 0 0 3px #ef4d56, 0px 21px 5px -18px rgba(122, 130, 177, 0.6);
  background: #ef4d56;
}
.ribbon4 .ribbon4-band.ribbon4-band-pink {
  box-shadow: 0 0 0 3px #fd3c97, 0px 21px 5px -18px rgba(122, 130, 177, 0.6);
  background: #fd3c97;
}
.ribbon4 .ribbon4-band.ribbon4-band-purple {
  box-shadow: 0 0 0 3px #7367f0, 0px 21px 5px -18px rgba(122, 130, 177, 0.6);
  background: #7367f0;
}
.ribbon4 .ribbon4-band.ribbon4-band-blue {
  box-shadow: 0 0 0 3px #0d6efd, 0px 21px 5px -18px rgba(122, 130, 177, 0.6);
  background: #0d6efd;
}
.ribbon4 .ribbon4-band.ribbon4-band-light {
  box-shadow: 0 0 0 3px #f4f6f9, 0px 21px 5px -18px rgba(122, 130, 177, 0.6);
  background: #f4f6f9;
}
.ribbon4 .ribbon4-band.ribbon4-band-dark {
  box-shadow: 0 0 0 3px #2b2d3b, 0px 21px 5px -18px rgba(122, 130, 177, 0.6);
  background: #2b2d3b;
}

.activity .activity-info {
  display: flex;
  margin: 5px 0;
  position: relative;
  min-height: 60px;
}
.activity .activity-info::before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 42px;
  left: 18px;
  border-left: 2px dotted var(--bs-border-color);
}
.activity .activity-info .icon-info-activity i {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--bs-secondary-color);
  box-shadow: 0px 0px 1px 0.25px;
  font-size: 18px;
  border: 1px solid var(--bs-border-color);
  margin-left: 2px;
}
.activity .activity-info .activity-info-text {
  margin-left: 16px;
  width: 100%;
}
.activity .activity-info .activity-info-text a,
.activity .activity-info .activity-info-text span {
  color: #7a82b1;
}

.icon-item {
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
}

.icon-item.icon-item-md {
  height: 2.5rem;
  width: 2.5rem;
}

.tracking-item {
  border-left: 1px solid var(--bs-border-color);
  position: relative;
  padding: 24px 30px;
  margin-left: 150px;
}
.tracking-item .tracking-date {
  position: absolute;
  left: -160px;
  width: 120px;
  text-align: right;
  font-weight: 500;
  font-size: 12px;
}
.tracking-item .tracking-icon {
  line-height: 18px;
  position: absolute;
  left: -9px;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--bs-secondary-bg);
}
.tracking-item .tracking-icon.icon-inner {
  color: #a8b5d1;
  font-size: 4px;
  box-shadow: 0px 0px 1px 1px #c1cde0;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 5px;
  padding: 7px 5px;
  text-align: center;
  background-color: rgba(0, 91, 111, 0.18);
  color: #005b6f;
}

.fc .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk h2 {
  font-size: 16px;
  text-transform: uppercase;
  color: #005b6f;
}
.fc .fc-toolbar.fc-header-toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 1.5rem;
}
.fc .fc-col-header-cell-cushion {
  padding: 10px 0;
  color: var(--bs-body-color);
}
.fc .fc-button {
  font-size: 0.812rem;
}
.fc .fc-button-primary {
  background: transparent;
  border: 1px solid var(--bs-border-color);
  color: #95a0c5;
  text-transform: capitalize;
  text-shadow: none;
  box-shadow: none;
}
.fc .fc-button-primary:hover {
  background: #005b6f;
  color: #ffffff;
  border-color: var(--bs-border-color);
}
.fc .fc-button-primary.active, .fc .fc-button-primary:focus, .fc .fc-button-primary:active {
  text-shadow: none;
  box-shadow: none;
}
.fc .fc-button-primary:disabled {
  background: rgba(0, 91, 111, 0.1);
  color: #005b6f;
  border-color: var(--bs-border-color);
  opacity: 1;
}
.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
  background: #005b6f;
  color: #ffffff;
  border-color: var(--bs-border-color);
}
.fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none;
}
.fc .fc-daygrid-day-number {
  color: var(--bs-body-color);
  font-weight: 500;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
  padding: 4px;
  border-radius: 24px;
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame {
  background-color: rgba(0, 91, 111, 0.18);
  border-radius: 8px;
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame .fc-daygrid-day-number {
  font-size: 16px;
  font-weight: 800;
}

.fc-license-message {
  display: none;
}

.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid var(--bs-border-color);
}

.fc-day-other {
  background-image: linear-gradient(-45deg, var(--bs-secondary-bg) 25%, transparent 25%, transparent 50%, var(--bs-secondary-bg) 50%, rgba(122, 130, 177, 0.2) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}

.fc-h-event .fc-event-main {
  color: #005b6f;
  font-weight: 500;
}

.fc-daygrid-dot-event .fc-event-title {
  font-weight: 500;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  background-color: var(--bs-card-bg);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  color: #005b6f;
  border-radius: 4px;
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}
[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}
.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: rgba(168, 181, 209, 0.5);
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 10px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}
.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.apexcharts-gridlines-horizontal,
.apexcharts-gridlines-vertical,
.apexcharts-grid,
.apexcharts-gridline,
.apexcharts-xaxis-tick,
.apexcharts-xaxis line,
.apexcharts-gridline line {
  pointer-events: none;
  stroke: var(--bs-border-color);
}

.apexcharts-xaxis line {
  display: none;
}

.apexcharts-grid-borders line,
.apexcharts-radar-series line,
.apexcharts-area-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-line-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-radar-series path,
.apexcharts-radar-series polygon {
  pointer-events: none;
  stroke: var(--bs-border-color);
}

.apexcharts text, .apexcharts-data-labels text {
  font-family: var(--bs-font-sans-serif) !important;
}

.apexcharts-legend-text {
  color: var(--bs-heading-color) !important;
  opacity: 0.8;
  font-family: var(--bs-font-sans-serif) !important;
}

.apexcharts-pie-label {
  fill: #ffffff !important;
}

.apexcharts-xaxis text,
.apexcharts-yaxis text {
  font-family: var(--bs-font-sans-serif) !important;
  fill: var(--bs-heading-color);
  opacity: 0.8;
}

.apexcharts-datalabels text,
.apexcharts-data-labels {
  font-family: var(--bs-font-sans-serif) !important;
  fill: var(--bs-heading-color);
}

.apexcharts-yaxis-annotation-label {
  color: var(--bs-heading-color);
  opacity: 0.8;
}

.apexcharts-point-annotations text,
.apexcharts-xaxis-annotations text,
.apexcharts-yaxis-annotations text {
  fill: #ffffff;
}

.tick text {
  font-family: var(--bs-font-sans-serif) !important;
  font-size: 0.75rem;
}

.extended-x-line,
.extended-y-line,
.horizontal-grid-line,
.vertical-grid-line {
  stroke: #d9e1ec;
}

.bar-chart .percentage-label,
.donut-text,
.legend-entry-name,
.legend-entry-value,
.tick text {
  fill: var(--bs-heading-color);
  opacity: 0.8;
}

.apex-charts {
  min-height: 10px;
}

.apexcharts-tooltip-title {
  font-family: var(--bs-font-sans-serif) !important;
  background: var(--bs-body-bg) !important;
  color: var(--bs-body-color);
  border-color: #f4f6f9;
}

.apexcharts-tooltip-text {
  font-family: var(--bs-font-sans-serif) !important;
}

.apexcharts-tooltip {
  font-family: var(--bs-font-sans-serif) !important;
  background: var(--bs-body-bg) !important;
  border-color: var(--bs-border-color) !important;
  white-space: normal;
}

.apexcharts-xaxistooltip {
  background-color: var(--bs-body-bg);
  border-color: var(--bs-body-bg);
  color: #2b2d3b;
}

.apexcharts-xaxistooltip-top:before,
.apexcharts-xaxistooltip-top:after {
  border-top-color: var(--bs-body-bg);
}

.apexcharts-xaxistooltip-bottom:before,
.apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: var(--bs-body-bg);
}

.apexcharts-xaxistooltip:before,
.apexcharts-xaxistooltip:after {
  border-color: var(--bs-body-bg);
}

.apexcharts-title-text,
.apexcharts-datalabel-label {
  color: var(--bs-heading-color);
  font-family: var(--bs-font-sans-serif) !important;
}

.apexcharts-datalabel-value {
  color: #f4f6f9;
}

.apexcharts-legend-marker {
  margin-right: 5px;
  vertical-align: middle;
  height: 5px;
}

.apexcharts-yaxis .apexcharts-yaxis-texts-g .apexcharts-text.apexcharts-yaxis-label,
.apexcharts-yaxis .apexcharts-yaxis-texts-g .apexcharts-text.apexcharts-xaxis-label,
.apexcharts-yaxis .apexcharts-xaxis-texts-g .apexcharts-text.apexcharts-yaxis-label,
.apexcharts-yaxis .apexcharts-xaxis-texts-g .apexcharts-text.apexcharts-xaxis-label,
.apexcharts-xaxis .apexcharts-yaxis-texts-g .apexcharts-text.apexcharts-yaxis-label,
.apexcharts-xaxis .apexcharts-yaxis-texts-g .apexcharts-text.apexcharts-xaxis-label,
.apexcharts-xaxis .apexcharts-xaxis-texts-g .apexcharts-text.apexcharts-yaxis-label,
.apexcharts-xaxis .apexcharts-xaxis-texts-g .apexcharts-text.apexcharts-xaxis-label {
  font-weight: 500;
}

.apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-value, .apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-label {
  font-family: var(--bs-font-sans-serif) !important;
  fill: var(--bs-heading-color);
  opacity: 0.8;
  color: var(--bs-heading-color);
  opacity: 0.8;
}

.apexcharts-grid-row {
  background-color: rgba(168, 181, 209, 0.2);
}

/*clock*/
.superpose {
  color: #eaeff5;
  height: 350px;
  width: 100%;
}
.superpose .superclock {
  position: relative;
  width: 300px;
  margin: auto;
}
.superpose .superclock .superclock1 {
  position: absolute;
  left: 10px;
  top: 10px;
}
.superpose .superclock .superclock2 {
  position: absolute;
  left: 60px;
  top: 60px;
}
.superpose .superclock .superclock3 {
  position: absolute;
  left: 110px;
  top: 110px;
}

.drop-shadow {
  filter: drop-shadow(0px 8px 4px #f4f6f9);
}

.tui-chart .tui-chart-chartExportMenu-area {
  z-index: 50;
}

.pill-bar .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
  clip-path: inset(0 0 4% 0 round 30px);
}

.gauge text {
  fill: var(--bs-heading-color);
  font-family: var(--bs-font-sans-serif) !important;
}

.tui-chart .tui-chart-chartExportMenu-area .tui-chart-chartExportMenu-button {
  display: none;
}
.tui-chart text {
  fill: var(--bs-body-color);
  font-family: var(--bs-font-sans-serif) !important;
}

.gmaps {
  height: 300px;
  background: #f4f6f9;
  border-radius: 3px;
}

.overlay {
  display: block;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  background: #005b6f;
  border-radius: 4px;
  padding: 5px 15px;
}

.overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}

.overlay_arrow.above {
  bottom: -9px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #005b6f;
}

.overlay_arrow.below {
  top: -9px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #005b6f;
}

.svgMap-map-wrapper {
  position: relative;
  width: 100%;
  padding-top: 50%;
  overflow: hidden;
  background: #f4f6f9;
}

.jvm-container text {
  font-family: var(--bs-font-sans-serif) !important;
  fill: var(--bs-heading-color) !important;
}

.jvm-tooltip {
  background-color: #005b6f;
  font-family: var(--bs-font-sans-serif) !important;
}

code[class*=language-],
pre[class*=language-] {
  color: #9efeff;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  tab-size: 4;
  hyphens: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-shadow: 0 1px #222245;
}

code[class*=language-] ::-moz-selection,
code[class*=language-] ::selection,
code[class*=language-]::-moz-selection,
code[class*=language-]::selection,
pre[class*=language-] ::-moz-selection,
pre[class*=language-] ::selection,
pre[class*=language-]::-moz-selection,
pre[class*=language-]::selection {
  color: inherit;
  background: #a599e9;
}

pre[class*=language-] {
  padding: 2em;
  margin: 0;
  overflow: auto;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  background: #151516;
}

:not(pre) > code[class*=language-] {
  padding: 0.1em;
  border-radius: 0.3em;
}

.token {
  font-weight: 400;
}

.token.cdata,
.token.comment,
.token.prolog {
  color: #b362ff;
}

.token.atrule,
.token.delimiter,
.token.important,
.token.keyword,
.token.selector {
  color: #ff9d00;
}

.token.attr-name,
.token.operator {
  color: #ffb454;
}

.token.punctuation {
  color: #fff;
}

.token.boolean {
  color: #ff628c;
}

.token.builtin,
.token.doctype,
.token.tag,
.token.tag .punctuation {
  color: #ff9d00;
}

.token.entity,
.token.symbol {
  color: #6897bb;
}

.token.number {
  color: #ff628c;
}

.token.constant,
.token.property,
.token.variable {
  color: #ff628c;
}

.token.char,
.token.string {
  color: #a5ff90;
}

.token.attr-value,
.token.attr-value .punctuation {
  color: #a5c261;
}

.token.attr-value .punctuation:first-child {
  color: #a9b7c6;
}

.token.url {
  color: #287bde;
  text-decoration: underline;
}

.token.function {
  color: #fad000;
}

.token.regex {
  background: #364135;
}

.token.bold {
  font-weight: 700;
}

.token.italic {
  font-style: italic;
}

.token.inserted {
  background: #0f0;
}

.token.deleted {
  background: #ff000d;
}

code.language-css .token.property,
code.language-css .token.property + .token.punctuation {
  color: #a9b7c6;
}

code.language-css .token.id {
  color: #ffc66d;
}

code.language-css .token.selector > .token.attribute,
code.language-css .token.selector > .token.class,
code.language-css .token.selector > .token.pseudo-class,
code.language-css .token.selector > .token.pseudo-element {
  color: #ffc66d;
}

.token.class-name {
  color: #fb94ff;
}

.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url {
  background: 0 0;
}

.line-highlight.line-highlight {
  margin-top: 36px;
  background: linear-gradient(to right, rgba(179, 98, 255, 0.17), transparent);
}

.line-highlight.line-highlight:before,
.line-highlight.line-highlight[data-end]:after {
  content: "";
}

pre.language-html,
pre.language-css,
pre.language-javascript {
  max-height: 220px;
  overflow-y: auto;
}
pre.language-html::-webkit-scrollbar,
pre.language-css::-webkit-scrollbar,
pre.language-javascript::-webkit-scrollbar {
  -webkit-appearance: none;
}
pre.language-html::-webkit-scrollbar:vertical,
pre.language-css::-webkit-scrollbar:vertical,
pre.language-javascript::-webkit-scrollbar:vertical {
  width: 5px;
  margin-right: 5px;
}
pre.language-html::-webkit-scrollbar:horizontal,
pre.language-css::-webkit-scrollbar:horizontal,
pre.language-javascript::-webkit-scrollbar:horizontal {
  height: 10px;
}
pre.language-html::-webkit-scrollbar-thumb,
pre.language-css::-webkit-scrollbar-thumb,
pre.language-javascript::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
  margin-right: 5px;
  border: none;
  transition: all 0.4s ease-out;
}
pre.language-html::-webkit-scrollbar-track,
pre.language-css::-webkit-scrollbar-track,
pre.language-javascript::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}
pre.language-html::-webkit-scrollbar-corner,
pre.language-css::-webkit-scrollbar-corner,
pre.language-javascript::-webkit-scrollbar-corner {
  background: transparent;
}
pre.language-html:hover::-webkit-scrollbar-thumb,
pre.language-css:hover::-webkit-scrollbar-thumb,
pre.language-javascript:hover::-webkit-scrollbar-thumb {
  background-color: rgba(244, 246, 249, 0.3);
  transition: all 0.4s ease-out;
}

.swal2-popup {
  background: var(--bs-secondary-bg);
  padding: 1.5rem 1.5rem;
}
.swal2-popup .swal2-title {
  font-size: 20px;
  font-weight: 500;
  color: var(--bs-body-color);
}
.swal2-popup .swal2-content {
  color: #95a0c5;
  font-size: 15px;
}
.swal2-popup.swal2-toast {
  background-color: var(--bs-secondary-bg);
  box-shadow: 0 0 0.625em rgba(85, 91, 126, 0.2);
}
.swal2-popup.swal2-toast .swal2-title {
  line-height: 1.2;
}
.swal2-popup .swal2-styled {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: none;
  padding: 0.375rem 0.75rem;
  font-size: 0.8125rem;
  border-radius: 0.25rem;
}
.swal2-popup .swal2-styled:focus {
  box-shadow: none;
}
.swal2-popup .swal2-styled.swal2-confirm {
  font-size: 0.812rem;
  background-color: #22c5ad;
}
.swal2-popup .swal2-styled.swal2-confirm:focus {
  box-shadow: none;
}
.swal2-popup .swal2-styled.swal2-deny {
  background-color: #005b6f;
  font-size: 0.812rem;
}
.swal2-popup .swal2-styled.swal2-deny:focus {
  box-shadow: none;
}
.swal2-popup .swal2-styled.swal2-cancel {
  font-size: 0.812rem;
  background-color: #ef4d56;
}
.swal2-popup .swal2-styled.swal2-cancel:focus {
  box-shadow: none;
}

.swal2-close:focus {
  box-shadow: none;
}

.swal2-footer {
  border-top: 1px solid var(--bs-border-color);
}

.swal2-actions .btn-success,
.swal2-actions .btn-danger {
  border: none;
}
.swal2-actions .btn-success:focus,
.swal2-actions .btn-danger:focus {
  box-shadow: none;
}

div:where(.swal2-container) .swal2-html-container {
  color: var(--bs-text-muted);
}
div:where(.swal2-container) input:where(.swal2-input),
div:where(.swal2-container) input:where(.swal2-file),
div:where(.swal2-container) textarea:where(.swal2-textarea) {
  border: 1px solid var(--bs-border-color);
  box-shadow: none;
  color: var(--bs-body-color);
}
div:where(.swal2-container) input:where(.swal2-input):focus,
div:where(.swal2-container) input:where(.swal2-file):focus,
div:where(.swal2-container) textarea:where(.swal2-textarea):focus {
  box-shadow: none;
  border: 1px solid #005b6f;
}

.swal2-icon {
  width: 3em;
  height: 3em;
  margin: 1em auto 1em;
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  top: 22px;
  width: 30px;
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 9px;
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 9px;
}

.swal2-icon .swal2-icon-content {
  font-size: 40px;
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  width: 40px;
  height: 70px;
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -23px;
  left: -13px;
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -12px;
  left: 36px;
}

.swal2-icon.swal2-success .swal2-success-fix {
  top: 0px;
  left: 12px;
  width: 6px;
  height: 60px;
}

.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 28px;
  left: 6px;
  width: 18px;
}

.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 23px;
  right: 4px;
  width: 30px;
}

@-webkit-keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 24px;
    left: 4px;
    width: 0;
  }
  70% {
    top: 27px;
    left: 0px;
    width: 22px;
  }
  84% {
    top: 30px;
    left: 8px;
    width: 16px;
  }
  100% {
    top: 28px;
    left: 6px;
    width: 18px;
  }
}
@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 24px;
    left: 4px;
    width: 0;
  }
  70% {
    top: 27px;
    left: 0px;
    width: 22px;
  }
  84% {
    top: 30px;
    left: 8px;
    width: 16px;
  }
  100% {
    top: 28px;
    left: 6px;
    width: 18px;
  }
}
@-webkit-keyframes swal2-animate-success-line-long {
  0% {
    top: 26px;
    right: 10px;
    width: 0;
  }
  65% {
    top: 26px;
    right: 10px;
    width: 0;
  }
  84% {
    top: 22px;
    right: 0;
    width: 34px;
  }
  100% {
    top: 23px;
    right: 4px;
    width: 30px;
  }
}
@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 40px;
    right: 30px;
    width: 0;
  }
  84% {
    top: 22px;
    right: 0;
    width: 34px;
  }
  100% {
    top: 23px;
    right: 4px;
    width: 30px;
  }
}
.noUi-target {
  background: rgba(168, 181, 209, 0.2);
  border-radius: 4px;
  border: 1px solid var(--bs-border-color);
  box-shadow: inset 0 1px 1px rgba(168, 181, 209, 0.1), 0 3px 6px -5px rgba(168, 181, 209, 0.2);
}

.noUi-horizontal .noUi-handle {
  width: 16px;
  height: 16px;
  right: -8px;
  top: -6px;
}

.noUi-horizontal {
  height: 7px;
}

/* Handle stripes;
 */
.noUi-handle {
  border: 2px solid var(--bs-border-color);
  border-radius: 50px;
  background: #005b6f;
  box-shadow: none;
}
.noUi-handle:before, .noUi-handle:after {
  height: 4px;
  width: 1px;
  background: #ffffff;
  left: 4px;
  top: 4px;
}

.noUi-handle:after {
  left: 7px;
}

.noUi-connect {
  background: #005264;
}

.noUi-vertical {
  width: 7px;
}
.noUi-vertical .noUi-handle {
  width: 16px;
  height: 16px;
  right: -5.5px;
  top: -10px;
}
.noUi-vertical .noUi-handle:before, .noUi-vertical .noUi-handle:after {
  width: 5px;
  height: 1px;
  left: 3px;
  top: 7px;
}
.noUi-vertical .noUi-handle:after {
  width: 5px;
  height: 1px;
  left: 3px;
  top: 4px;
}
.noUi-vertical .noUi-origin {
  top: 0;
}

.noUi-marker {
  position: absolute;
  background: rgba(168, 181, 209, 0.2);
}

.noUi-marker-sub {
  background: rgba(168, 181, 209, 0.3);
}

.noUi-marker-large {
  background: rgba(168, 181, 209, 0.3);
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 12px;
}

.example-val:before {
  content: "Value: ";
}

.noUi-tooltip {
  display: none;
}

.noUi-active .noUi-tooltip {
  display: block;
}

.noUi-tooltip {
  border: 1px solid var(--bs-border-color);
  background: #fafbfc;
  color: #7a82b1;
  padding: 2px 4px;
  font-size: 10px;
}

ul.listree-submenu-items {
  border-left: 1px dashed var(--bs-border-color);
}
ul.listree-submenu-items li a {
  margin-bottom: 12px;
  display: block;
}

.folder-icon div.listree-submenu-heading.collapsed:before {
  content: "\f07b";
  font-family: "Font Awesome 5 Free";
}

.folder-icon div.listree-submenu-heading.expanded:before {
  content: "\f07c";
  font-family: "Font Awesome 5 Free";
}

[dir=rtl] ul.listree-submenu-items {
  border-right: none;
}

.listree-submenu-heading {
  padding-bottom: 12px;
}